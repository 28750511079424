.channel__widget-section__wrapper {
  &:not(:first-of-type) {
    border-top: 1px solid @global-border-color;
  }

  &:not(:last-of-type) {
    .channel__widget-section__content {
      padding-bottom: @global-whitespace-base;
    }
  }

  &:not(.is-active) {
    .channel__widget-section__content {
      display: none;
    }
  }
}

.channel__widget-section__trigger {
  font-size: @global-font-size-small;
  text-transform: uppercase;
  color: @global-color-primary;
  height: @global-height;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: @global-whitespace-small;

  &:hover,
  &:focus {
    color: @global-color-primary-hover;
  }

  @svg-load caret-down url(../../../node_modules/rhinostyle/src/svg/caret-down.svg) {
    .icon-stroke {
      stroke: @global-color-primary;
    }
  }

  &::after {
    content: '';
    position: absolute;
    background-color: transparent;
    background-image: svg-inline(caret-down);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    width: @global-font-size-xsmall;
    height: @global-font-size-xsmall;
    top: 50%;
    right: @global-whitespace-small;
    transform: translateY(-50%);
  }

  @svg-load caret-up url(../../../node_modules/rhinostyle/src/svg/caret-up.svg) {
    .icon-stroke {
      stroke: @global-color-primary;
    }
  }

  .is-active & {
    &::after {
      background-image: svg-inline(caret-up);
    }
  }
}

.channel__widget-section__content {
  padding: @global-whitespace-small 0 0 @global-whitespace-small;
}

.channel__widget__position__wrapper {
  display: flex;
  align-items: center;
  font-size: @global-font-size-small;

  > * + * {
    margin-left: @global-whitespace-small;
  }
}

.channel__widget__position__image {
  flex: 0;
  width: 2.5rem;
  height: 2.5rem;
}
