//
// Variables
//

@app-panel-header-height: @button-height-large;
@app-panel-summary-width: (40.6rem + @global-whitespace-base); // Takes care of `margin-left` applied to wrapper
@app-background-color: @global-color-gray-lightest;

//
// Component
//


//
// Body
//


// 1. Fix primary color change bug
body {
  // 1
  backface-visibility: hidden;
  overflow: hidden;
}

// App wrapper
.app-wrapper {
  background: @app-background-color;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  z-index: @zindex-app-wrapper;
  height: 100%;
  flex: 1;

  @media (min-width: @global-screen-medium-min) {
    margin-left: (@app-nav-width-large + @app-sidebar-width);
  }
}

.app-wrapper-limited {
  background: @app-background-color;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  z-index: @zindex-app-wrapper;
  height: 100%;
  flex: 1;

  @media (min-width: @global-screen-medium-min) {
    margin-left: 6rem;
  }
}

.app-wrapper__container {
  height: 100%;
  display: flex;
  position: relative;
  overflow-y: auto;
}

// Utility for stopping touch-scroll from bleeding on-top of navigation overlay
.has-open-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .app-wrapper {
    pointer-events: none;
  }
}


// IE11 bugfix for min-height
// Used in calculateViewportHeight
// https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
.app-reactroot {
  display: flex;
  flex-direction: column;
}


//
// Chrome Browser
//


.chrome-browser {
  // Fix input text alignment bug in Chrome
  input.form__control {
    line-height: @global-height;
  }
}


//
// Panels
//


.app-panels {
  display: flex;
  width: 100%;
  align-items: stretch;

  // Give the header (only when inside of panels) some left/right space since it would bump against the sides
  @media (max-width: @global-screen-medium-max) {
    .app-page__header {
      margin-left: @global-whitespace-base;
      margin-right: @global-whitespace-base;
    }
  }

  @media (min-width: @global-screen-medium-min) {
    margin-right: @global-whitespace-base;
  }

  > * {
    flex: 1 0 100%;
    display: flex;
    overflow: hidden;

    > * {
      width: 100%;
    }
  }
}

.list-panel,
.summary-panel {
  display: flex;
  flex-direction: column;
}

.list-panel {
  @media (min-width: @global-screen-medium-min) {
    padding-left: @global-whitespace-base;
  }
}

.summary-panel {
  @media (min-width: @global-screen-medium-min) {
    margin-left: @global-whitespace-base;
  }

  // Going ahead and setting the wrapper width to what it is on bigger screens since it remains consistent
  &__wrapper {
    @media (min-width: @global-screen-large-min) {
      flex-basis: @app-panel-summary-width;
    }
  }

  &__body-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow: hidden;
  }
}

// Active states
.app-panels--profile {
  @media (max-width: @global-screen-large-max) {
    transform: translateX(-100%);
  }

  @media (min-width: @global-screen-large-min) {
    .list-panel__wrapper {
      flex-basis: calc(100% - @app-panel-summary-width); /* stylelint-disable-line */ // https://github.com/stylelint/stylelint/issues/4114
    }
  }
}

// Sticky profile
.app-panels--hide-icons-desktop {
  // Hide profile toggle and close buttons on desktop
  @media (min-width: @global-screen-large-min) {
    .app-page__header__action__close,
    .convo__header__actions {
      display: none;
    }
  }
}

// List
.list-panel__body,
.list-panel__outer__body {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
}

.list-panel__body__scroll {
  flex: 1;
  border-top: 1px solid @global-border-color;
  height: auto !important;

  .resource-group {
    // Take away top border of first child
    // Only doing first since an area like Members has multiple resource groups in it
    &:first-child {
      border-top-width: 0;
    }

    &--inbox {
      &:first-child {
        border-top: 1px solid @global-border-color;
      }
    }

    @media (max-width: @global-screen-medium-max) {
      border-left: 0;
      border-right: 0;
    }
  }
}

.list-panel__body__scroll--no-border {
  border-top: 0;

  .resource-group {
    border-top-width: 1px;
  }
}


// Summary
.summary-panel__body {
  display: flex;
  flex: 1;
  flex-direction: column;
  background: @global-color-white;
  border: 1px solid @global-border-color;
  border-bottom: 0;
  overflow-y: scroll;
  position: relative;

  @media (max-width: @global-screen-medium-max) {
    border-left: 0;
    border-right: 0;
  }

  &--category {
    background: inherit;
    border: none;
  }
}

.summary-panel__content {
  padding: @global-whitespace-base;
  // 1
  display: flex;
  flex-direction: column;
  position: relative;

  // 2
  > * {
    flex-shrink: 0;
  }
}

// Increase bottom padding for absolutely positioned loader
.summary-panel__content--search {
  padding-bottom: (@global-whitespace-base * 2);
}

.summary-panel__intro-wrapper {
  text-align: center;
  margin-bottom: @global-whitespace-base;

  & + & {
    border-top: 1px solid @global-border-color;
    padding: @global-whitespace-base 0;
  }
}

.summary-panel__intro__avatar {
  margin-bottom: @global-whitespace-base;
}

.summary-panel__intro__title {
  font-weight: @global-font-weight-bold;
  font-size: @global-font-size-medium;
  display: flex;
  justify-content: center;
}

.summary-panel__intro__title__online-status-icon {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  align-self: center;
  margin-right: @global-whitespace-small;
}

.summary-panel__intro__sub {
  font-size: @global-font-size-small;
  color: @global-font-color-muted;
}

.summary-panel__intro__desc {
  font-size: @global-font-size-small;
  color: @global-font-color-muted;
  margin-top: @global-whitespace-xsmall;
}

.summary-panel__intro__actions {
  margin-top: @global-whitespace-base;

  &__more-button {
    height: 35px;
    width: 45px;

    .icon {
      height: 20px;
      width: 20px;
    }
  }
}

.summary-panel__consent {
  margin-top: @global-whitespace-base;
  border: 1px solid @global-border-color;
  background: @global-color-panel;
  text-align: left;
}

.summary-panel__note {
  padding: @global-whitespace-base;
  background: @global-color-panel;
  margin-top: @global-whitespace-base;
  margin-bottom: @global-whitespace-base;
  text-align: left;
}

.summary-panel__note--important {
  background: @global-color-danger-light;
  border: 1px solid @global-color-danger;
}

.summary-panel__section {
  border-top: 1px solid @global-border-color;
  padding: @global-whitespace-base 0;

  &:last-child {
    padding-bottom: 0;
  }

  &:not(.summary-panel__section--full) {
    @media (max-width: @global-screen-small-max) {
      > * + * {
        margin-top: @global-whitespace-small;
      }
    }

    @media (min-width: @global-screen-small-min) {
      display: flex;

      > * {
        &:first-child {
          flex-basis: 150px;
          flex-shrink: 0;
        }

        + * {
          margin-left: @global-whitespace-base;
          word-break: break-word;
        }
      }
    }
  }
}

.summary-panel__slidingradio__label {
  flex-basis: 125px;
  flex-shrink: 0;
  margin-bottom: @global-whitespace-base;
}

.summary-panel__section__label {
  display: flex;
  font-size: @global-font-size-small;
  color: @global-font-color-muted;
  text-transform: uppercase;
  font-weight: @global-font-weight-bold;
  line-height: @global-line-height-computed;
}

.summary-panel__section-label__view-library-button {
  color: @global-color-primary;
  font-size: @global-font-size-small;
  font-weight: @global-font-weight-normal;
}

.summary-panel__section__content--full {
  margin-top: @global-whitespace-base;
  padding-bottom: @global-whitespace-base;
}

.summary-panel__section__content--indented {
  margin-left: @global-whitespace-base;
}

.summary-panel__section__label__action {
  margin-left: auto;
  cursor: pointer;
}

.summary-panel__end {
  padding-top: @global-whitespace-large;
}

//
// Convo thread panels
//

// Search
.search-panel__result {
  border: 1px solid @global-border-color;
  box-shadow: @app-shadow-heavy;
  cursor: pointer;
  transition: 0.25s transform @global-trans-timing-cubic;
  transform-origin: center center;

  &:hover,
  &:focus {
    transform: scale(1.0125);
    backface-visibility: hidden;

    .convo__item {
      background: @resource-active-background;
    }
  }

  // Ignore any click events on interior links for search results
  .convo__item__body {
    pointer-events: none;
  }

  & + & {
    margin-top: @global-whitespace-base;
  }
}

.summary-panel__results__loader {
  position: absolute;
  bottom: @global-whitespace-small;
  left: 50%;
  transform: translateX(-50%);
}

.summary-panel__appointments__loader {
  text-align: center;
  padding: @global-whitespace-large;
}

// Assign
.assign-panel__footer {
  margin-top: @global-whitespace-large;
  display: flex;
  align-items: center;
}

.assign-panel__footer__left {
  margin-right: @global-whitespace-small;

  > * {
    &.is-active {
      color: @global-color-primary;

      .icon {
        fill: @global-color-primary-light;
      }
    }

    + * {
      margin-left: @global-whitespace-base;
    }
  }
}

.assign-panel__footer__right {
  margin-left: auto;
}

.nav-tabs__wrapper {
  .nav-tabs {
    display: block;
    text-align: center;
  }

  .nav-tabs__item {
    display: inline-block;

    &::before,
    &::after {
      border: none;
    }

    &.is-active {
      border-bottom: @global-color-primary solid 2px;

      .nav-tabs__item__link {
        color: @global-color-primary;
        font-weight: @global-font-weight-bold;
      }
    }
  }

  .nav-tabs__item__link {
    font-size: @global-font-size-small;
    padding: 0;
    color: @global-color-gray;

    &::after {
      border: none !important;
      background: none !important;
    }
  }

  .nav-tabs__item--header {
    padding: 0 @global-whitespace-xsmall 0.2rem @global-whitespace-xsmall;
    font-size: @global-font-size-base;
    font-weight: bold;
  }
}

.summary-panel__profile-icon {
  width: 75px;
  height: 75px;
  background: @global-color-gray-light;
  border-radius: 50%;
  color: @global-color-white;
  display: inline-block;
  margin: 0 0 15px;

  .u-flex {
    justify-content: center;
    margin: 7px 0 0;
  }

  svg {
    width: 48px !important;
    height: 48px !important;
    margin: 10px 0 0;
  }
}

.summary-panel__dropdown {
  width: 100%;
  text-align: left;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 3rem;

  .button__text-wrapper {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    display: flex;
    width: 100%;
  }

  &--accent {
    .dropdown__toggle__text {
      color: @global-color-accent;
    }
  }
}

.summary-panel__dropdown__title {
  font-weight: @global-font-weight-bold;
  font-size: @global-font-size-medium;
  display: flex;
  justify-content: flex-start;
  margin-bottom: @global-whitespace-base;
}
