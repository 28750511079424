//
// Variables
//

@pdf-toolbar-height: @button-height-base;

//
// Component
//


// Really... I mean REALLY hacky way to fix https://github.com/philipwalton/flexbugs/issues/197
// @TODO Look into revamping this
// @NOTE This is fixed in Safari 11
// 1. Set height of cover header
// 2. Set `height` to fix flex property not actually growing the content... even though the browser's inspector says it does. Also takes into account the bottom toolbar.
.pdf-attachment__cover {
  .cover__header {
    // 1
    height: 50px;
  }

  .cover__body {
    overflow: hidden;
    // 2
    height: calc(100% - 50px);
  }
}

// 1. Match height of surrounding `<CoverBody />`
// 2. Override default styles set by container
.pdf-attachment__cover__body-container {
  // 1
  height: 100%;
  // 2
  padding: 0;
  max-width: 100%;
  position: relative;
}

// Match height of surrounding `<CoverBody />`
.pdf-attachment__container {
  height: 100%;
  overflow: auto;

  .page {
    position: relative;
    box-shadow: 0 15px 35px fade(#32325d, 10%), 0 5px 15px fade(#000, 7%);
    max-width: 800px;
    margin: @global-whitespace-large auto;
    width: 100% !important;
    height: auto !important;
  }

  .canvasWrapper {
    width: auto !important;
    height: auto !important;
  }

  canvas {
    width: 100% !important;
    height: auto !important;
  }
}

// 1. Match height of small buttons used
.pdf-attachment__cover__toolbar {
  background: @global-color-white;
  border-top: 1px solid @global-border-color;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: @pdf-toolbar-height;
  display: flex;
  align-items: center;
  overflow: hidden;
  z-index: 2;

  > * {
    flex: 1;
  }
}

.pdf-attachment__cover__toolbar__center {
  text-align: center;
}

.pdf-attachment__cover__toolbar__right {
  margin-left: auto;
  text-align: right;
}

.pdf-attachment__cover__loading {
  margin: @global-whitespace-base auto;
  text-align: center;
}

// Unsupported browsers
// 1. Extend container styles
.pdf-attachment__container__unsupported {
  // 1
  .cover__body__container();
  .cover__body__container--small();
}
