
.variable-message__compose__container,
.category-variable-message__compose__container {
  border: 1px solid @global-border-color;
  border-top-left-radius: @global-border-radius;
  border-top-right-radius: @global-border-radius;
  color: #404040;
  background-color: #fcfcfc;
  min-height: @global-height;
  padding: @global-whitespace-medium;
}

.category-variable-message__compose__container {
  min-height: @global-height * 2;
}

//
// Variables
//

@variable-message-compose-bg: @input-bg;
@variable-message-compose-border-color: @input-border;
@variable-message-compose-color: @input-color;
@variable-message-compose-min-height: @input-height-base;
@variable-message-compose-horizontal-padding: @input-padding-base;
@variable-message-compose-vertical-padding: @textarea-vertical-padding;
@variable-message-compose-border-radius: @input-border-radius;

//
// Component
//

.variable-message__header {
  display: flex;
  align-items: baseline;
  margin-bottom: @global-whitespace-small;
}

.variable-message__label {
  display: block;
  margin-bottom: 0;
  margin-right: @global-whitespace-small;
}

.variable-message__reset {
  margin-left: auto;
}

.variable-message__compose {
  word-break: break-word;

  &[contenteditable='true'],
  &[contenteditable='false'] {
    background-color: @variable-message-compose-bg;
    border: 1px solid @variable-message-compose-border-color;
    border-top-left-radius: @variable-message-compose-border-radius;
    border-top-right-radius: @variable-message-compose-border-radius;
    color: @variable-message-compose-color;
    min-height: @variable-message-compose-min-height;
    padding: @variable-message-compose-vertical-padding @variable-message-compose-horizontal-padding;
  }

  &:focus {
    outline: 0;
  }

  &[contenteditable][placeholder]:empty::before {
    content: attr(placeholder);
    color: @input-color-placeholder;
  }
}

.variable-message__footer {
  padding: @variable-message-compose-vertical-padding @variable-message-compose-horizontal-padding;
  display: flex;
  flex-direction: column;
  border: 1px solid @variable-message-compose-border-color;
  border-top: none;
  border-bottom-left-radius: @variable-message-compose-border-radius;
  border-bottom-right-radius: @variable-message-compose-border-radius;

  @media (min-width: @global-screen-xsmall-min) {
    justify-content: center;
  }
}

.category-message__footer {
  display: flex;
  flex-direction: row;
  border: 1px solid @variable-message-compose-border-color;
  border-bottom-left-radius: @variable-message-compose-border-radius;
  border-bottom-right-radius: @variable-message-compose-border-radius;

  @media (min-width: @global-screen-xsmall-min) {
    justify-content: center;
  }
}

.variable-message__footer__variable__list,
.category-message__footer__variable__list {
  padding: @variable-message-compose-vertical-padding @variable-message-compose-horizontal-padding;

  > button:not(:last-child) {
    margin-right: @global-whitespace-small;
  }
}

.category-message__footer__category__list {
  border-right: 1px solid @variable-message-compose-border-color;
}

.category-message__footer__category {
  border-bottom: 1px solid @variable-message-compose-border-color;
  border-left: none;
  border-top: none;
  cursor: pointer;
}

.category-message__footer__category:last-child {
  border-bottom: none;
}

.category-variables__section {
  overflow-y: scroll;
  max-height: 117px;
  padding: @global-whitespace-base;
  flex: 1;
}

.variable-button {
  margin-left: 0.5rem;
}

.category-message__footer__category__active {
  border-left: 4px solid @global-color-primary;
}

.category-message__explanation {
  font-size: @global-font-size-small;
  font-weight: @global-font-weight-bold;

  @media (max-width: @global-screen-xsmall-max) {
    margin-top: @global-whitespace-xsmall;
  }
}

.variable-message__explanation {
  font-size: @global-font-size-small;
  font-weight: @global-font-weight-bold;

  @media (max-width: @global-screen-xsmall-max) {
    margin-top: @global-whitespace-xsmall;
  }
}

.variable-message__character-count {
  .u-text-small;

  margin-top: @global-whitespace-base;
  color: @global-font-color-muted;
  margin-left: auto;
  text-align: right;
  cursor: default;
}

.variable-message__character-count--warning {
  color: @global-color-accent;
  font-weight: @global-font-weight-bold;
}

.variable-message__preview {
  margin-top: @form-group-spacing;

  .msg--inbound {
    word-break: break-word;
  }
}

.variable-message--loading {
  position: fixed;
}

.category-web-view {
  display: block;

  @media (max-width: @global-screen-small-max) {
    display: none;
  }
}


.category-message__dropdown {
  display: none;

  .button__text-wrapper {
    justify-content: space-between;
  }

  .icon {
    margin: 0 !important;
  }

  @media (max-width: @global-screen-small-max) {
    display: block;
  }
}

.category-variable-list {
  border: 1px solid @variable-message-compose-border-color;
}

.variable-message__add-emoji-container {
  background-color: #fcfcfc;
  border: 1px solid @global-border-color;
  border-right: none;
  border-top-left-radius: @global-border-radius;
  padding: @global-whitespace-medium @global-whitespace-small;
}

.vcard {
  border-radius: 1.1rem;
  display: inline flex;
  flex-direction: row;
  hyphens: auto;
  line-height: inherit;
  overflow-wrap: break-word;
  white-space: normal;
  align-items: center;
  padding: 0.5rem 1rem;
  word-wrap: break-word;
  z-index: 1;
  margin-top: 1rem;
  justify-content: flex-end;
  max-width: 30rem;
  margin-right: @global-whitespace-small;

  .avatar--default circle {
    background-color: @global-color-white;
    fill: @global-color-white;
  }

  .avatar--default text {
    fill: @global-color-gray-darker;
  }
}

.vcard-inbound {
  background: darken(@global-color-gray-lightest, 6%);
}

.vcard-outbound {
  color: white;
  background: @global-color-primary;
}

.vcard-avatar {
  margin: 0 1rem;
}

.vcard-icon {
  height: 1.4rem;
}

.variable-message__warning {
  margin: 1.5rem;
}

.variable-message__snippet {
  color: orange;
}

.variable-message__compose__container--readonly {
  background-color: #e3e3e3;
}
