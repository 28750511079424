.am__default {
  background: @global-color-panel;
  border-radius: @global-border-radius;
  padding: @global-whitespace-large;
}

.am__validation-bump {
  @media (min-width: @global-screen-small-min) {
    margin-top: (@global-height - @global-line-height-computed) / 2;
  }
}

.am__offices {
  margin-bottom: @global-whitespace-large;
}

.am__offices__header {
  display: flex;

  @media (max-width: @global-screen-xsmall-max) {
    display: none;
  }
}

.am__offices__body {
  border: 1px solid @global-border-color;
}

.am__offices__row {
  display: flex;

  & + & {
    border-top: 1px solid @global-border-color;
  }

  @media (max-width: @global-screen-xsmall-max) {
    flex-direction: column;
  }
}

.am__offices__office,
.am__offices__channel {
  padding: @global-whitespace-base;

  .am__offices__header & {
    font-size: @global-font-size-small;
    color: @global-font-color-muted;
    text-transform: uppercase;
    padding-top: 0;
    padding-bottom: @global-whitespace-small;
  }
}

.am__offices__office {
  flex-basis: 50%;

  .am__offices__header & {
    padding-left: 0;
  }

  @media (max-width: @global-screen-xsmall-max) {
    text-align: center;
    padding-bottom: 0;
  }

  @media (min-width: @global-screen-small-min) {
    flex-basis: 100%;
  }
}

.am__offices__office__name {
  font-weight: @global-font-weight-bold;
}

.am__offices__office__address {
  .u-text-muted;
  .u-text-small;
  .u-m-t-0;
}

.am__offices__channel {
  flex-basis: 50%;

  @media (min-width: @global-screen-small-min) {
    flex-basis: 400px;
  }
}

.am__hours-input input {
  width: 6rem;
  // Used to remove the up and down arrows on number inputs
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.am__trigger-input input {
  width: 45rem;
  // Used to remove the up and down arrows on number inputs
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
