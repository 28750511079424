// This is a VERY app-specific expansion to the ConnectedParty list that shows an additonal, non `<Resource />` entity within a `<ResourceGroup />`


.resource__wild-active {
  .mixin-resource-active-tab();

  background: @resource-active-background;
  padding: @global-whitespace-large;
  position: relative;
}

.u-border-remove {
  border: 0;
}
