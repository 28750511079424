//
// Variables
//

@app-header-spacing: (@global-whitespace-medium * 2);
@app-header-small-break: 374px;
//
// Component
//

// 1. Get it above content
.app-header {
  align-items: center;
  background: @global-color-white;
  display: flex;
  flex: none;
  height: @app-header-height;
  padding-left: @global-whitespace-base;
  position: relative;
  // 1
  z-index: 10;
  border-bottom: 1px solid @global-color-gray-lighter;

  @media (max-width: @global-screen-small-max) {
    background-color: @global-color-primary;
    border-bottom: 1px solid @global-color-primary;
    padding-right: @global-whitespace-medium;
  }
}


//
// App Header Button Group
//
.app-header__right {
  display: flex;
  margin-left: auto;
  align-items: center;

  .app-header__icon,
  .app-header__dropdown .dropdown__toggle {
    padding: @global-whitespace-medium;
  }

  @media (max-width: @global-screen-small-max) {
    .app-header__icon,
    .app-header__dropdown .dropdown__toggle {
      padding: 7px;
      margin-left: 7px;
    }
  }
}
//
// App Header Dropdown and App header Icon without dropdown
//


// 1. Match height of header
.app-header__dropdown__trigger,
.app-header__icon {
  height: @app-header-height; // 1
  font-size: @app-icon-toggle-size;
  color: @global-font-color;


  &.is-active,
  &:hover,
  &:focus {
    color: @global-color-primary;
  }

  @media (max-width: @global-screen-small-max) {
    font-size: @global-font-size-large;
    padding: 0;
    color: @global-color-white;

    &.is-active,
    &:hover,
    &:focus {
      color: @global-color-white;
    }

    .loader-circle {
      width: 2rem;
      height: 2rem;
    }
  }
  // "Placeholder-like" label
  .button__text-content {
    margin-left: @global-whitespace-small;
    font-size: @global-font-size-base;
    color: @global-font-color-muted;

    @media (max-width: @global-screen-small-max) {
      display: none;
    }
  }
}

/* Calculate offset of dropdowns in header relative to app-header to avoid certain actions from being hidden
* This is an issue because we keep track of the `height` and `min-height` of the app-wrapper, instead of using `100vh`
* This will be slightly off if there is a system alert since that height is variable
* Due to the info alert taking extra height some of the options weere hidding, therefore  subtracting height of info alert component from the 100vh.
*
* We also add an approximate `80px` value to take care of how Android and Safari
* keep the bottom nav bar, which makes the `100vh` calculation taller than it actually is. *sigh*
*/

// App header height + `.dropdown__menu` padding + offset of `dropdown__menu` + info-alert height
@dropdown-viewport-offset: calc(100vh - (@app-header-height + (@global-whitespace-xsmall * 2) + (@dropdown-menu-offset * 2) + @system-alert-message-height)); /* stylelint-disable-line */ // https://github.com/stylelint/stylelint/issues/4114

// Variation on offset for CCR menus which require overflow due to additional list options.
@dropdown-viewport-offset-overflow: calc(100vh - (@app-header-height + (@global-whitespace-xsmall * 2) + (@dropdown-menu-offset * 4.8))); /* stylelint-disable-line */ // https://github.com/stylelint/stylelint/issues/4114

.app-header__dropdown {
  .dropdown__menu__scroll {
    max-height: @dropdown-viewport-offset;

    // 1
    .is-mobile & {
      max-height: calc(@dropdown-viewport-offset - 80px); /* stylelint-disable-line */ // https://github.com/stylelint/stylelint/issues/4114
    }
  }

  // dropdown css for ccr login
  .dropdown__menu--wide {
    .is-mobile & {
      max-height: calc(@dropdown-viewport-offset-overflow - 80px); /* stylelint-disable-line */ // https://github.com/stylelint/stylelint/issues/4114
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  &__logout {
    @media screen and (min-width: (@app-header-small-break + 1)) {
      .is-hidden;
    }
  }
}

.app-header__member {
  display: flex;
  align-items: center;

  > span {
    @media (max-width: 1050px) {
      display: none;
    }
  }

  &:hover,
  &:focus,
  &.is-active {
    background: transparent;
  }

  .dropdown__toggle {
    padding: 0 @global-whitespace-base;

    @media (max-width: @global-screen-small-max) {
      padding: 20px 7px;
    }
  }

  .avatar {
    margin-top: 0;
    box-shadow: 0 0 0 2px @global-color-white, 0 0 0 3px @base-link-color;
  }
}

.app-header__avatar {
  width: 3.5rem;
  height: 3.5rem;

  @media (max-width: @global-screen-small-max) {
    width: 2rem;
    height: 2rem;
  }
}


//
// App Header Menu (Burger)
//


.app-header__menu {
  cursor: pointer;
  margin-right: @app-header-spacing;
  position: relative;
  z-index: 1;

  @media (min-width: @global-screen-medium-min) {
    display: none;
  }
}

.app-header__menu__badge {
  align-items: center;
  background: @global-color-danger;
  border-radius: 50%;
  color: @global-color-white;
  display: flex;
  font-size: @app-font-size-micro;
  font-weight: @global-font-weight-bold;
  min-height: 2rem;
  justify-content: center;
  line-height: 1;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(65%, -65%);
  min-width: 2rem;
  padding: 0 2px;

  &:empty {
    display: none;
  }
}

.app-header__menu__bar {
  background-color: @global-color-primary;
  height: 0.3rem;
  width: 2.4rem;
  border-radius: @global-border-radius;

  & + & {
    margin-top: @global-whitespace-xsmall;
  }

  @media (max-width: @global-screen-small-max) {
    background-color: @global-color-white;
  }
}

.app-header__logo {
  display: none;

  img {
    height: @global-height-large;
    width: 100%;
  }

  @media (max-width: @global-screen-small-max) {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
