// Hide "fudged" label to not introduce more space than needed on smaller screens
.ooo__select {
  @media (max-width: @global-screen-xsmall-max) {
    label {
      display: none;
    }
  }
}

// 1. Nudge "closed" checkbox down on desktop to better align with selects
// 2. Right align closed option
.ooo__closed {
  @media (min-width: @global-screen-small-min) {
    margin-top: @global-whitespace-small; // 1

    .rhinobox {
      justify-content: flex-end; // 2
    }
  }
}
