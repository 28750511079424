//
// variables
//

@color-picker-current-height: @global-height-small;
@color-picker-icon-size: @color-picker-current-height;

//
// Component
//

.color-picker__wrapper {
  position: relative;
}

.color-picker__current {
  height: @color-picker-current-height;
  border-radius: @global-border-radius;
  cursor: pointer;
  box-shadow: @global-shadow-depth-1;
  margin-right: (@color-picker-icon-size + @global-whitespace-small);
  position: relative;

  @svg-load color-wheel url(../images/color-wheel.svg);

  &::after {
    content: '';
    position: absolute;
    background-color: transparent;
    background-image: svg-inline(color-wheel);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    width: @color-picker-icon-size;
    height: @color-picker-icon-size;
    top: 50%;
    right: -(@color-picker-icon-size + @global-whitespace-small);
    transform: translateY(-50%);
  }
}

.color-picker__picker {
  position: absolute;
  z-index: 2;
  top: (@color-picker-current-height + @global-whitespace-small);
}
