.field-value__old {
  text-decoration: line-through;
  background-color: @global-color-danger-lightest;
}

.field-value__new {
  background-color: @global-color-success-lightest;
}

.column-alignment__key {
  min-width: 160px;
  text-transform: capitalize;
  word-break: break-word;
  width: 160px;

  @media (max-width: @global-screen-small-min) {
    min-width: 93px;
  }
}

.audit-log__grid {
  min-height: 100vh;

  .expand-row {
    padding: 1px 10px;
  }

  @media (max-width: @global-screen-small-min) {
    overflow: scroll;
  }

  @media (max-width: @global-screen-small-max) {
    padding: @global-whitespace-medium;
  }

  .daterange__dropdown {
    text-align: center;

    .button {
      font-size: @global-font-size-base;

      @media (max-width: @global-screen-xsmall-max) {
        font-size: @global-font-size-small;
      }
    }
  }

  .dropdown {
    .button {
      @media (max-width: @global-screen-small-max) {
        height: @global-whitespace-large;
      }
    }
  }

  .audit-log__filter-wrapper {
    .dropdown__toggle__text {
      @media (max-width: @global-screen-small-max) {
        font-size: @global-font-size-small;
      }
    }

    .button {
      @media (max-width: @global-screen-small-max) {
        height: @global-whitespace-large;
      }
    }
  }

  .dropdown__menu__item__link {
    @media (max-width: @global-screen-small-max) {
      padding: @global-whitespace-small;
      font-size: @global-font-size-small;
    }
  }

  .dropdown__menu__scroll {
    @media (max-width: @global-screen-small-max) {
      max-height: 180px;
    }
  }

  .dropdown__menu {
    @media (max-width: @global-screen-xsmall-min) {
      max-width: 11rem;
    }
  }
}

.business-hours__details {
  margin-top: 0.1em !important;
}

.audit-log__filter-wrapper {
  margin-bottom: @global-whitespace-medium;

  @media (min-width: @global-screen-small-min) {
    .audit-log__pagination {
      margin-top: 0;
    }
  }

  .audit-log__pagination-wrapper {
    padding: 0;
  }
}

.audit-log__filter-label {
  font-size: @global-font-size-small;
  color: @global-color-gray;
  line-height: @global-whitespace-xlarge;
  height: @global-whitespace-xlarge;
}

.audit-log__nologs_wrapper {
  margin-top: @global-whitespace-large;
}

.expand-row__detail {
  margin-top: 1px !important;
  font-size: 1.5rem;
}

span.expand-row__detail {
  text-transform: uppercase;
  color: @global-color-gray;
}

.clear-all__button {
  font-size: @global-font-size-small;
}

.audit-log__title {
  padding-top: @global-whitespace-large;
  position: relative;
}

.contact_dropdown {
  & + .dropdown__menu {
    @media (max-width: @global-screen-xsmall-min) {
      left: auto;
      right: -2rem;
      margin: 0 auto;

      &::before {
        left: auto;
        right: 7rem;
      }
    }
  }
}

.contact_dropdown-small {
  & + .dropdown__menu {
    @media (max-width: @global-screen-xsmall-min) {
      left: auto;
      right: -2rem;
      margin: 0 auto;

      &::before {
        left: auto;
        right: 7rem;
      }
    }

    @media (max-width: @global-screen-nano-min) {
      left: 0;
      right: auto;

      &::before {
        left: auto;
        right: 20rem;
      }
    }
  }
}

.multi-select__dropdown__height {
  @media (max-width: @global-screen-small-max) {
    max-height: 180px !important;

    & > div {
      max-height: 180px !important;
    }
  }
}
