@slider-width: 13rem;
@slider-height: 3rem;
@slider-border-radius: 50px;
@slider-background: @global-color-gray-darker;
@slider-track-color: @global-color-secondary;
@slider-vertical-rotate: rotate(270deg);

.slider__wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 45px;
  height: 45px;
  margin: @global-whitespace-base;
  align-items: center;
  display: flex;

  @media only screen and (max-width: @global-screen-xsmall-max) {
    margin: @global-whitespace-small;
  }

  &.is-vertical {
    transform-origin: center;
    transform: @slider-vertical-rotate;
    -webkit-transform: @slider-vertical-rotate;
    -moz-transform: @slider-vertical-rotate;
  }

  input[type="range"] {
    -webkit-appearance: none;
    -moz-apperance: none;
    position: relative;
    width: @slider-width;
    height: @slider-height;
    border-radius: @slider-border-radius;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      background-color: transparent;
      box-shadow: none;
      width: @slider-height;
      height: @slider-height;
    }

    &::-moz-range-progress {
      background-color: @slider-track-color;
    }

    &::-moz-focusring {
      outline: 1px solid transparent;
      outline-offset: -1px;
    }

    &::-ms-fill-lower {
      background-color: @slider-track-color;
    }

    &::-moz-range-track {
      background-color: @slider-background;
    }

    &::-ms-fill-upper {
      background-color: @slider-background;
    }

    &::-moz-range-thumb {
      opacity: 0;
    }
  }
}
