//
// Variables
//


@convo-avatar-position: -5.4rem;
@convo-item-padding: @global-whitespace-base;
@convo-item-padding-offset: 5.3rem;
@convo-tools-height: 4.2rem;
@convo-send-size: 2.6rem;
@convo-typing-indicator-height: (@global-font-size-xsmall + @global-whitespace-small);
@convo-z-index: 100;
@global-color-accent-lighter: rgb(253,230,203);
//
// Component
//


.convo {
  display: flex;
  flex-direction: column;
}


//
// Convo Body
//


// 1. Border override for end-user experience
.convo__body {
  background: @global-color-white;
  border-top: 1px solid @global-border-color;
  border-left: 1px solid @global-border-color;
  border-right: 1px solid @global-border-color;
  flex: 1;

  @supports (-webkit-touch-callout: none) {
    z-index: @convo-z-index;
  }

  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar {
    background: @global-color-white;
  }
}

.convo__body__intro {
  color: @global-color-gray;
  margin: 0 auto;
  max-width: 800px;
  padding: 2rem @global-whitespace-large;
  text-align: center;
}

.convo__body__intro__headline {
  font-weight: @global-font-weight-bold;
}

.convo__body__intro__subheadline {
  font-style: italic;
}

.convo__body__preview-header__wrapper {
  text-align: center;
  margin-top: @global-whitespace-base;
}

.convo__body__preview-header__title {
  color: @global-color-accent;
  font-style: italic;
  margin-bottom: @global-whitespace-small;
}

.convo__body__preview-header__selection-title {
  font-size: @global-font-size-large;
  font-weight: @global-font-weight-bold;
  margin: @global-whitespace-small 0;
}

.convo__body__preview-header__selection-note {
  margin-top: 0;
  margin-bottom: @global-whitespace-large;
}

//
// Convo Channels
//

// 1. Force dropdown text to wrap here because of "push" issue that is cutting off the panel
.convo__channels {
  width: 100%;
  height: @global-height;
  align-items: center;
  display: flex;
  padding: 0 @global-whitespace-base;
  justify-content: space-between;

  > * + * {
    margin-left: @global-whitespace-small;
  }

  .dropdown__menu__container {
    margin-bottom: @global-font-size-large;
  }

  .convo__channels__select {
    .is-active > .dropdown__menu__item__link {
      &,
      &:hover,
      &:focus {
        color: @global-color-gray-darker;
        background-color: @global-color-gray-lighter;
      }
    }
  }

  .dropdown__toggle {
    .dropdown__toggle__caret {
      margin-right: 0;
    }
  }

  .dropdown__menu {
    left: -@global-whitespace-small;
  }

  @media (max-width: @global-screen-xsmall-min) {
    .dropdown__menu {
      max-width: 30rem;
    }
  }
}

.convo__channels__label {
  display: flex;
  align-items: center;

  &:hover {
    color: @global-color-primary;
  }
}

.convo__channels__label__icon {
  font-size: @global-font-size-large;

  @media (min-width: @global-screen-xsmall-min) {
    margin-right: @global-whitespace-small;
  }
}

.convo__channels__label__text {
  margin-right: @global-whitespace-medium;

  @media (max-width: @global-screen-xsmall-max) {
    display: none;
  }
}


.convo__channels--notification {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.convo__character-count__container {
  justify-self: flex-end;
  margin-left: @global-whitespace-medium;
}

//
// Convo Footer
//


// 1. Do not render if we don't have any content
// Gets around an issue where the scaffolding is shown before there's content to be populated while the thread is loading
.convo__footer {
  background: @global-color-white;
  border-left: 1px solid @global-border-color;
  border-right: 1px solid @global-border-color;
  position: relative;

  &.is-loading {
    border-top-color: transparent;

    > * {
      opacity: 0;
    }
  }
}

// Override specifically for message alerts
// @TODO Consider moving this over as a type to RS and remove additional props not needed in component
.convo__footer__alert {
  border-radius: 0;
  background: @app-icon-active-fill-primary;
  text-align: center;
  border: 0;

  .alert__title {
    justify-content: center;
    font-size: @global-font-size-base;
  }

  .alert__body {
    .u-text-small;

    a {
      .u-text-primary;

      display: inline-block;
      text-decoration: none !important;
    }

    margin-top: @global-whitespace-small;
  }

  &--preview {
    border-top: 1px solid @global-border-color;
  }
}

.convo__footer__nav {
  min-height: 26px;
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid @global-border-color;
  justify-content: space-between;
}

.convo__footer__nav__left {
  display: flex;

  @media (max-width: @global-screen-xsmall-max) {
    .convo__footer__nav__item {
      padding: @global-whitespace-xsmall @global-whitespace-xsmall;
    }
  }
}

.convo__footer__nav__item {
  color: @global-font-color;
  font-size: @global-font-size-small;
  padding: @global-whitespace-xsmall @global-whitespace-base;

  &:hover,
  &:focus {
    color: @global-color-primary;
  }

  &.is-active {
    font-weight: @global-font-weight-bold;
    color: @global-color-primary;
    cursor: default;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      bottom: -1px;
      background: @global-color-primary;
      height: 1px;
    }
  }
}

.convo__footer__actions {
  display: flex;
  background: @app-background-color;

  @media (max-width: @global-screen-xlarge-max) {
    flex-direction: column;
  }

  @media (min-width: @global-screen-xlarge-min) {
    border: 1px solid @global-border-color;
    flex-direction: row;
    align-items: center;
    height: @global-height;
    margin: 0 -1px;
  }
}

.convo__footer__actions--broadcast-modal {
  border-left: 1px solid @global-border-color;
  border-right: 1px solid @global-border-color;
  border-bottom: 1px solid @global-border-color;
  display: flex;
  align-items: center;
  height: @global-height;
  background: @app-background-color;
  padding: 0 @global-whitespace-small;
  margin-bottom: @global-whitespace-small;
}

.convo__footer__actions--contact-assign-panel {
  border: 1px solid @global-border-color;
  display: flex;
  align-items: center;
  height: @global-height;
  margin: 0 -1px;
  background: @app-background-color;
  padding: 0 @global-whitespace-small;
}

.convo__message__add-actions {
  display: flex;
  align-items: center;
  height: @global-height;
  padding-left: @global-whitespace-small;

  @media (max-width: @global-screen-xlarge-max) {
    border-bottom: 1px solid @global-border-color;
    border-top: 1px solid @global-border-color;
    padding-right: @global-whitespace-base;
  }

  @media (min-width: @global-screen-xlarge-min) {
    border-right: 1px solid @global-border-color;
  }
}

.convo__message__add-actions--chat {
  display: flex;
  height: @global-height;
  border: 1px solid @global-border-color;
  padding: 0 @global-whitespace-small;
}

.convo__message__add-actions--note {
  display: flex;
  height: @global-height;
  padding: 0 @global-whitespace-small;
  border-top: 1px solid @global-border-color;
}

.convo__message__icon-button {
  color: @global-font-color-muted;
  font-size: @global-font-size-large;
  margin: 0 @global-whitespace-small;
  cursor: pointer;

  &:hover {
    color: @global-color-primary;
  }
}

.consent-pending {
  color: @global-color-warning;
}

.consent-granted {
  color: @global-color-secondary;
}

.consent-declined {
  color: @global-color-danger;
}


//
// Convo Header
//


.convo__header {
  align-items: center;
  display: flex;
  flex: none;
  height: @global-height;
  margin-bottom: @global-whitespace-base;
  position: relative;
  z-index: 100;

  @media (max-width: @global-screen-medium-max) {
    padding: 0 @global-whitespace-base;
  }

  @supports (-webkit-touch-callout: none) {
    z-index: @modal-zindex - 1;
  }
}

.convo__header--variation {
  .convo__header__actions {
    @media (max-width: @global-screen-medium-max) {
      > *:not(.convo__mobile-dropdown) {
        display: none;
      }
    }

    @media (min-width: @global-screen-medium-min) {
      .convo__mobile-dropdown {
        display: none;
      }
    }
  }
}

// 1. Offset of bottom margin on convo header
// 2. Offset of icon + icon right spacing + convo padding
.convo__header__connected-parties__dropdown-wrapper {
  // 1
  bottom: calc(~"-1px + " (-@global-whitespace-base)); /* stylelint-disable-line */
  // 2
  left: calc(@icon-size ~"+" (@global-whitespace-base + @global-whitespace-base)); /* stylelint-disable-line */
  position: absolute;
  -webkit-overflow-scrolling: touch;

  @media (min-width: @global-screen-medium-min) {
    left: (@icon-size + @global-whitespace-small); // 2
  }

  &:focus,
  & .is-open {
    .dropdown__toggle__icon {
      color: @global-color-primary;
      fill: @app-icon-active-fill-primary;
    }
  }

  .convo__header__connected-parties__dropdown {
    margin-bottom: @global-whitespace-base;
  }

  .convo__header__connected-parties__close-button {
    position: absolute;
    top: @global-whitespace-small;
    right: @global-whitespace-base;
  }

  // 1. Override of dropdown width
  // 2. Remove bottom padding so `<Resource />` can fit against it
  .dropdown__menu {
    min-width: 26rem; // 1
    padding-bottom: 0; // 2
  }

  .summary-panel__note {
    margin: 0.5rem 0;
  }

  &--left {
    left: 0;

    @media (max-width: @global-screen-medium-min) {
      left: @icon-size;
    }
  }
}


.convo__header__connected-parties__top {
  display: flex;
  flex-direction: column;

  .dropdown__menu__header {
    padding: 0;
  }

  .dropdown__menu__close {
    margin-left: auto;
    font-size: @global-font-size-small;
  }
}

// Remove padding from container since this contains `<Resource />`
.convo__header__connected-parties__body {
  padding: 0;
}

.convo__header__title {
  min-width: 0;
  text-align: left;
  margin-right: @global-whitespace-small;
}


.convo__header__title__name {
  font-weight: @global-font-weight-bold;
  font-size: @global-font-size-medium;
  display: flex;
}

.convo__header__title__badges {
  @media (min-width: @global-screen-medium-min) {
    display: none;
  }
}

.convo__header__title__deets {
  color: @global-color-gray;
  font-size: @global-font-size-small;
  margin: 0.3rem;
  .u-text-overflow;

  &--padded {
    margin-left: @icon-size + @global-whitespace-xsmall;
  }
}

.convo__header__assinees {
  font-size: @global-font-size-xsmall;

  &--label {
    color: @global-color-accent;
  }

  &--padding {
    padding-left: 2rem;
  }
}

.convo__header__title__deets__chat-thread {
  color: @global-color-gray;
  font-size: @global-font-size-small;
  .u-text-overflow;
}

.convo__header__highlights__button {
  color: @global-color-gray-darker;

  &:hover {
    color: @global-color-primary;
    fill: @app-icon-active-fill-primary;
  }

  @media (min-width: @global-screen-medium-min) {
    display: none;
  }
}

// 1. So dropdown and filter icon (only for CCRs) don't get cut-off
.convo__header__actions__container {
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-direction: row-reverse;
  align-items: center;
}

.convo__header__actions {
  display: flex;
  align-items: center;
  padding-right: (@global-whitespace-xsmall / 2);

  .convo__header__actions__button {
    height: @global-height;
  }

  .convo__header__actions__button--close-convo {
    .icon {
      width: 1.2em;
      height: 1.2em;
    }
  }

  > * + * {
    margin-left: @global-whitespace-base;
  }
}

.convo__header__actions__button {
  height: @global-height;
  color: @global-font-color;
  font-size: @global-font-size-large;


  .convo__mobile-dropdown & {
    font-size: (@global-font-size-large * 1.15);
  }

  .mixin-filter-notify();

  &:hover,
  &:focus,
  &.is-active {
    .icon {
      color: @global-color-primary;
      fill: @app-icon-active-fill-primary;
    }
  }
}

.convo__header__actions__label {
  .u-text-small;

  display: none;

  @media only screen and (max-width: @global-screen-medium-max) {
    display: block;
  }
}

//
// Convo Inner
//


.convo__inner {
  display: flex;
  flex: 1;
  flex-direction: column;
}


//
// Convo Item
//


.convo__item {
  padding: @convo-item-padding;
  position: relative;

  &__translation {
    font-size: @app-font-size-micro;
    cursor: pointer;
    color: @global-font-color;
  }
}

.convo__item--active-search-result {
  background: @resource-active-background;
}

.convo__item--outbound {
  text-align: right;
  margin-left: auto;
  width: 100%;

  .convo__item__body__avatar {
    right: @convo-avatar-position;
  }

  .convo__item__footer {
    justify-content: flex-end;
  }

  @media (min-width: @global-screen-xsmall-min) {
    padding-right: @convo-item-padding-offset;
  }
}

.convo__item__tooltip__wrapper {
  position: absolute;
  display: none;
  z-index: 2;
  padding-bottom: @global-whitespace-small;
  width: 100%;
  justify-content: center;
  cursor: text;
}

.convo__item__tooltip {
  color: @global-color-gray-darker;
  background-color: @global-color-white;
  padding: @global-whitespace-medium;
  border-radius: @global-border-radius;
  text-align: center;
  word-wrap: break-word;
  min-width: 215px;
  box-shadow: @global-shadow-depth-1;
  position: relative;
  top: 5px;

  &::after {
    content: '';
    width: @tooltip-arrow-size;
    height: @tooltip-arrow-size;
    position: absolute;
    border: 1px solid @global-border-color;
    background-color: @global-color-white;
    border-bottom-left-radius: @global-border-radius;
    border-right: 0;
    border-top: 0;
    top: ~'calc(-(@{tooltip-arrow-size} / 2) - 1px)'; // 1
    left: 50%;
    transform: translateX(-50%) rotate(135deg);
  }

  &__title {
    font-size: @global-font-size-small;
    font-weight: @global-font-weight-bold;
    margin-bottom: @global-whitespace-small;
  }

  &__description {
    text-align: center;
    margin: 0;
    font-size: @global-font-size-small;
  }

  &__failed-icon {
    font-size: @global-font-size-xsmall;
    color: @global-color-danger;
    opacity: 1;
  }

  &__message-detail {
    margin-top: @global-whitespace-small;
    text-align: left;
    margin-bottom: 0;
    padding-top: @global-whitespace-small;
    color: @global-color-primary;
    border-top: 1px solid @global-border-color;
    font-size: @global-font-size-small;
  }
}

.convo__item__checkbox {
  @media (min-width: @global-screen-small-min) {
    align-self: center;
  }
}

.convo__item__checkbox--right {
  margin-left: @global-whitespace-base;
}

.convo__item__checkbox--left {
  margin-right: @global-whitespace-base;
}

.convo__item--inbound {
  margin-right: auto;
  width: 100%;

  .convo__item__body__avatar {
    left: @convo-avatar-position;
  }

  @media (min-width: @global-screen-xsmall-min) {
    padding-left: @convo-item-padding-offset;
  }
}

.convo__item__body {
  margin: @global-whitespace-xsmall 0;
  position: relative;
}

.convo__item__body__avatar {
  bottom: 0;
  display: none;
  position: absolute;

  @media (min-width: @global-screen-xsmall-min) {
    display: block;
  }
}

.convo__item__body__msg {
  margin: 2px;
  max-width: 640px;
  min-height: 4rem; // match avatar height
  text-align: left;
  word-break: break-word;

  &--not-sent {
    background: @global-color-gray;

    &::before {
      border-color: @global-color-gray;
    }
  }

  &--unsent {
    background: @global-color-accent-lighter;
    text-transform: capitalize;

    &::before {
      border-color: @global-color-accent-lighter;
    }
  }
}

.convo__item__body__msg--pim {
  margin-right: 1.5rem;

  &.has-border {
    border: 1px solid #7ec0e3;
    z-index: 2;
    position: relative;

    &::before {
      z-index: 2;
    }
  }

  .pim-icon {
    position: absolute;
    right: -2.7rem;
    top: -0.8rem;
    background: #7ec0e3;
    padding: 0.4rem 0.6rem;
    border-radius: @global-border-radius * 4;
    color: white;
    font-size: @global-font-size-xsmall;
  }

  &.msg__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: none;
  }
}

.convo__item__body__footer--pim {
  position: absolute;
  width: 100%;
  height: 100%;

  &::before {
    border-bottom-right-radius: 100%;
    border-right-style: solid;
    border-right-width: 2rem;
    left: -1rem;
    height: 1.7rem;
    bottom: 0;
    position: absolute;
    width: 3rem;
    z-index: 1;
    border-right-color: #7ec0e3;
    content: "";
    box-sizing: inherit;
  }
}

.convo__item__body__msg.u-text-rtl {
  direction: rtl;
  text-align: right;
}

.convo__item__body__msg__attachments {
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  // If it's an inbound message - align to the right
  .convo__item--outbound & {
    align-items: flex-end;
  }

  // If the next element is some message text, give it some top space
  + .convo__item__body__msg {
    margin-top: @global-whitespace-xsmall;
  }

  > * + * {
    margin-top: @global-whitespace-xsmall !important;
  }
}

.convo__item__body__msg__attachments__file {
  margin: 0;

  // Override text decoration utility
  a {
    text-decoration: none !important;
  }

  & + & {
    margin-top: @global-whitespace-small;
  }
}

.convo__item__body__msg__attachments__file__text {
  .u-text-underline;

  &.is-note {
    color: @global-color-primary;
  }
}

.convo__item__attachment-icon {
  &.is-note {
    color: @global-color-primary;
  }
}

// 1. Hardcode height to resolve `scrollToBottom()` within each thread-view not calculating offset correctly.
.convo__item__body__msg__attachments__link {
  display: block;
  // 1
  height: 15rem;

  &:not(.is-loaded),
  &.has-error {
    background: @global-color-panel;
    border-radius: @msg-border-radius;
  }

  &.is-loaded {
    img {
      opacity: 1;
    }
  }

  // 1. Fudge size
  &.has-error {
    width: 15rem; // 1
    height: 15rem; // 1
    position: relative;
    cursor: default;
    pointer-events: none;

    @svg-load attachment url(../../../node_modules/rhinostyle/src/svg/attachment.svg) {
      .icon-stroke {
        stroke: @global-font-color;
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 35px;
      height: 35px;
      background-color: transparent;
      background-image: svg-inline(attachment);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 2;
    }
  }

  // 1. Hide by default, fade-in from `is-loaded` class on wrapper link (above)
  img {
    border-radius: @msg-border-radius;
    border: solid 1px #d8d8d8;
    height: auto;
    max-height: 15rem; // 1
    max-width: 30rem;
    opacity: 0;
    transition: opacity 0.25s @global-trans-timing-cubic;
  }
}

.convo__item__header {
  font-size: @global-font-size-small;
  font-weight: @global-font-weight-bold;
}

.convo__item__footer {
  align-items: center;
  color: @global-font-color-muted;
  display: flex;
  font-size: @app-font-size-micro;

  &__message {
    position: relative;
    display: inline-block;
    cursor: pointer;

    &:hover {
      .convo__item__tooltip__wrapper {
        display: flex;
      }
    }
  }
}


//
// Thread Events
//

.convo__event__wrapper {
  margin: @global-whitespace-base;
  display: flex;
  word-break: break-word;

  &:last-child {
    .convo__item__tooltip__wrapper {
      bottom: 20px;
    }

    .convo__item__tooltip {
      &::after {
        top: unset;
        bottom: calc(-0.5rem - 1px);
        transform: translateX(-50%) rotate(315deg);
      }
    }
  }
}

.convo__event__wrapper--inverted {
  flex-direction: row-reverse;
}

.convo__event {
  padding: @global-whitespace-small;
  text-align: center;
  background: @app-icon-active-fill-primary;
  font-size: @global-font-size-small;
  border-radius: @global-border-radius;
  flex-grow: 1;
}

.convo__suggested__route {
  padding: @global-whitespace-small;
  text-align: center;
  background: #f1f1f1;
  font-size: @global-font-size-small;
  border-radius: @global-border-radius;
  flex-grow: 1;
  color: @global-color-gray-dark;
  border: 1px solid #7ec0e3;
}

.convo__event__icon {
  .icon--large;

  margin-right: @global-whitespace-xsmall;
}

.convo__event__icon--assign {
  .icon-stroke {
    stroke: @global-color-primary;
  }
}

.convo__event__icon--complete {
  .icon-stroke {
    stroke: @global-color-success;
  }
}

.convo__event__icon--video {
  margin-right: @global-whitespace-small;
}

.convo__event__subtext {
  .u-text-muted;

  font-size: @global-font-size-xsmall;

  &__link {
    cursor: pointer;
  }
}


//
// Convo Message
//


.convo__message__container {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  padding: @global-whitespace-base;

  > * + * {
    margin-left: @global-whitespace-small;
  }

  .is-note & {
    background: lighten(@msg-note-bg, 5%);
  }

  &--disable {
    background-color: @global-color-primary-light;
  }
}

// Used in chat where there is no nav
.convo__message__container--no-nav {
  border-top: 1px solid @global-border-color;
}

.convo__message__container--mentions {
  align-items: center;
}

.convo__message__add {
  font-size: @global-font-size-large;
}

.convo__message {
  flex: 1;
}

.convo__message__send {
  flex: none;
  color: @global-color-secondary;
  font-size: @convo-send-size;

  &:hover,
  &:focus {
    color: @global-color-secondary;
  }

  &[disabled] {
    color: @global-font-color-muted;
  }

  &:not([disabled]) {
    .icon {
      fill: @global-color-secondary-light;
    }
  }

  // Override loader circle color
  .loader-circle {
    border-color: @global-color-secondary;
  }
}

.convo__message__compose__attachments {
  padding: 0 @global-whitespace-base @global-whitespace-base @global-whitespace-base;

  .is-note & {
    background: lighten(@msg-note-bg, 5%);
    padding: @global-whitespace-base;
  }
}

// Override `min-height` prop since our auto-expand plugin will take care of it
.convo__message__textarea {
  textarea {
    min-height: 0;
  }

  &--disable {
    textarea {
      color: @global-color-black;
    }
  }
}


//
// Convo Typing Indicator
//


.convo__typing-indicator {
  opacity: 0;
  padding: 0 @global-whitespace-base;
  line-height: 1;
  display: flex;
  align-items: center;
  height: @convo-typing-indicator-height;

  // Override for `<Loader />` component
  .loader-pulse {
    > * {
      height: 0.6rem;
      width: 0.6rem;

      + * {
        margin-left: @global-whitespace-xsmall;
      }
    }
  }

  // One-way transition
  &.is-active {
    transition: opacity 0.25s @global-trans-timing-cubic;
    opacity: 1;
  }
}

.convo__typing-indicator__info {
  color: @global-font-color-muted;
  font-size: @app-font-size-micro;
}

.convo__medicaid-view {
  padding-top: 0;

  @media (max-width: @global-screen-small-max) {
    padding-left: 0;

    .resource-group__scroll {
      max-height: 200px !important;
    }
  }
}

.convo__medicaid-pagination {
  padding-top: @global-whitespace-medium;
}

.analytics__medicaid-tab {
  .dropdown__toggle__text {
    @media (max-width: @global-screen-small-max) {
      font-size: @global-font-size-small;
    }
  }
}

.analytics__contact-dropdown {
  & + .dropdown__menu {
    @media (max-width: @global-screen-xsmall-min) {
      left: auto;
      right: -2rem;
      margin: 0 auto;

      &::before {
        left: auto;
        right: 7rem;
      }
    }
  }
}

.analytics__contact-dropdown--small {
  & + .dropdown__menu {
    @media (max-width: @global-screen-xsmall-min) {
      left: auto;
      right: -2rem;
      margin: 0 auto;

      &::before {
        left: auto;
        right: 7rem;
      }
    }

    @media (max-width: @global-screen-nano-min) {
      left: 0;
      right: auto;

      &::before {
        left: auto;
        right: 20rem;
      }
    }
  }
}

.convo__selection-form__wrapper {
  background-color: @global-color-gray-lighter;

  @media (max-width: @global-screen-nano-min) {
    max-height: 40rem;
  }

  @media (max-width: @global-screen-small-min) {
    max-height: 50rem;
  }

  .form {
    padding: @global-whitespace-small @global-whitespace-base;
    height: 100%;
    overflow: scroll;
  }
}

.convo__selection-form__header {
  border-bottom: 1px solid @global-color-gray-light;
  padding-bottom: @global-whitespace-xsmall;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.convo__selection-form__body {
  margin-top: @global-whitespace-small;

  @media (max-width: @global-screen-small-min) {
    display: none;
  }

  &--mobile-is-visible {
    display: block;
  }
}

.convo__selection-form__toggle-button {
  @supports (-webkit-touch-callout: none) {
    z-index: @convo-z-index + 2;
  }

  @media (min-width: @global-screen-small-min) {
    display: none;
  }

  cursor: pointer;
  position: absolute;
  height: 26px;
  width: 50px;
  right: 0;
  top: -24px;
  text-align: center;
  border-radius: @global-border-radius;
  background-color: @global-color-gray-lighter;
}

.convo__selection-form__footer {
  display: flex;
  justify-content: space-between;
  margin-top: @global-whitespace-base;
  flex-wrap: wrap;
}

.convo__selection-form__offices {
  option {
    white-space: normal;
  }
}

// inbox-thread-styles
.language-selector-dropdown {
  font-size: @global-font-size-small;
  line-height: normal;
  height: auto;
}

.translator-button {
  float: left;
  margin: @global-whitespace-xsmall 0 0;
}

.translator-icon {
  color: @global-color-gray-light;

  &--active {
    color: @global-color-primary;
  }

  &--inactive {
    color: @global-color-gray-dark;
  }
}

.translated-text-language-label {
  flex-basis: 100%;
}

.convo__body--inbox {
  border-top: none;

  .convo__item__footer {
    display: block;
  }
}

.convo__item--selection-mode-enabled,
.convo__event--selection-mode {
  cursor: pointer;
}

.convo__header__close-conversation {
  padding-right: @global-whitespace-medium;
  padding-top: @global-whitespace-medium;

  svg {
    height: 24px;
    width: 24px;
    left: 1px;
  }

  .convo__header__actions__button {
    border-radius: 50%;
    width: 41px;
    height: 41px;
    text-align: center;
    display: block;
    cursor: pointer;
    padding: @global-whitespace-xsmall 0 0;

    &:hover {
      background: @tooltip-background-light;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
    }
  }
}

.close-conversation-text {
  color: @global-color-primary;
  padding: @global-whitespace-xsmall @global-whitespace-small;
  display: block;
}

.load-more__wrapper {
  width: 100%;
  min-height: @global-whitespace-large;
}

.convo__event__button {
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: @global-font-size-small;
  margin-right: @global-whitespace-xsmall;

  &.is-outlined {
    background: @global-color-white;
    margin-left: @global-whitespace-xsmall;
    margin-right: 0;
  }
}

.u-text-divider {
  padding-right: @global-whitespace-xsmall;
  padding-left: @global-whitespace-xsmall;
}

.inbox__event__message {
  height: 3em;
  border-radius: 3.125em;
  hyphens: auto;
  line-height: @msg-line-height;
  overflow-wrap: break-word;
  padding: 1rem;
  position: relative;
  vertical-align: middle;
  word-wrap: break-word;
  text-overflow: ellipsis;
  z-index: 1;
  overflow: hidden;

  &:empty {
    display: none;
  }

  a {
    .u-text-underline;
  }
}

.msg--primary--outbound {
  border: solid;
  border-color: @global-color-primary;
}

.convo-filter {
  border-top: 1px solid @global-color-gray-lighter;
  border-left: 1px solid @global-color-gray-lighter;
  border-right: 1px solid @global-color-gray-lighter;
  position: relative;
  background: @global-color-white;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 5;
  padding: @global-whitespace-small;
  font-size: @global-font-size-xsmall;
  line-height: 1.5rem;

  .convo-filter--open {
    visibility: hidden;
    padding: @global-whitespace-small;
    z-index: 6;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    background-color: @global-color-success;
    color: @global-color-white;
    flex-direction: column;
    transition: visibility 0.5s 0.3s;

    @supports (-webkit-touch-callout: none) {
      z-index: @convo-z-index + 2;
    }

    .convo-filter__title {
      margin-bottom: @global-whitespace-xsmall;
    }
  }

  .convo-filter--closed {
    display: flex;
  }

  &:hover {
    .convo-filter--open {
      visibility: visible;
    }
  }

  .convo-filter__title {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
      height: 2rem;
      width: 2rem;
    }
  }

  &__channel-name {
    border-left: 1px solid #b9b9b9;
    padding-left: 1rem;
    margin-left: 1rem;
  }

  @supports (-webkit-touch-callout: none) {
    z-index: @convo-z-index + 1;
  }
}

.vcard-icon-size {
  font-size: 2.3rem;
}

.attachment-size {
  border: solid 1px #d8d8d8;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 200px;
  height: 120px;
}
