//
// Variables
//

@widget-padding: @global-whitespace-large;

//
// Component
//

.widget__wrapper {
  width: 35rem;

  @media (max-width: @global-screen-nano-min) {
    width: 32rem;
  }
}

#widget-app {
  overflow-y: auto;
}

.widget__header {
  .trimChildren();

  position: relative;
  padding: @widget-padding;
}

.widget__header__title {
  font-size: @global-font-size-medium;
  font-weight: @global-font-weight-normal;
  text-transform: uppercase;
  margin: 0;
}

.widget__close {
  position: absolute;
  right: @global-whitespace-base;
  top: @global-whitespace-base;
}

.widget__body {
  .trimChildren();

  padding: @widget-padding;

  @media (max-width: @global-screen-nano-min) {
    padding: @global-whitespace-small;
  }

  background-color: #fff;
}

.widget__bottom-link {
  .u-text-muted;

  display: flex;
  align-items: center;
  justify-content: center;
}

.widget__rg-logo {
  .icon--bump-up;

  position: relative;
  width: 1.5em;
  height: 1.5em;
  margin-right: @global-whitespace-small;
}
