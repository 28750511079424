@saved-content-summary-width: (45rem + @global-whitespace-base); // Takes care of `margin-left` applied to wrapper

.saved-content-preview {
  @media (min-width: @global-screen-medium-min) {
    .list-panel__wrapper {
      flex-basis: calc(100% - @saved-content-summary-width); /* stylelint-disable-line */ // https://github.com/stylelint/stylelint/issues/4114
    }
  }
}

.saved-content-preview__actions {
  @media (max-width: @global-screen-medium-max) {
    transform: translateX(-100%);
  }
}

.saved-content-preview__thread {
  @media (max-width: @global-screen-medium-min) {
    .summary-panel__wrapper {
      display: none;
    }
  }
}

.copy-to-modal {
  margin-top: -@global-whitespace-medium;

  .global-search-header__desktop {
    width: 100%;
  }

  .dropdown__toggle__caret {
    margin-right: 0 !important;
  }

  .form__addon__item--right {
    padding-right: 0 !important;

    .dropdown__toggle {
      width: 90px;
      height: @global-height;
      border: transparent;

      @media (max-width: @global-screen-small-max) {
        width: 50px;
      }
    }
  }

  .dropdown__toggle__text {
    font-size: @global-font-size-base;

    @media (max-width: @global-screen-small-max) {
      display: none;
    }
  }
}

.sepration {
  border-top: 1px solid @global-color-gray-lighter;
}

.saved-content__panel__title {
  font-weight: @global-font-weight-bold;
  font-size: @global-font-size-medium;
}

.saved-content__summary-panel__details-section {
  border-top: none;
  padding-top: 0;
}

.saved-content__panel__note {
  font-size: @global-font-size-small;
}

.saved-content__panel__file {
  display: flex;
  align-items: center;
  margin: @global-whitespace-small 0;
}

.saved-content__panel__file__icon {
  padding: (@global-whitespace-small * 1.2) (@global-whitespace-small / 1.5);
  color: @global-color-primary;
  font-size: @global-font-size-small;
  border: 1px solid @global-border-color;
  border-radius: @global-border-radius;
  align-self: flex-start;
}

.saved-content__panel__file__name {
  max-width: 82%;
  margin: 0 @global-whitespace-small;
  word-wrap: break-word;
  color: @global-color-primary;
  font-size: @global-font-size-small;
  cursor: pointer;

  &:hover {
    color: darken(@global-color-primary, 5%);
  }
}

.saved-content__panel__file__download-icon {
  color: @global-font-color-muted;
  cursor: pointer;
}

.saved-content__panel__audit-information {
  font-size: @global-font-size-xsmall;
  color: @global-font-color-muted;
}

.saved-content__accordion__header {
  font-weight: @global-font-weight-bold;
  font-size: @global-font-size-medium;
  color: @global-font-color-muted;
}

.saved-content__panel__link__wrapper {
  display: flex;
  justify-content: space-between;
  background-color: @global-color-gray-lightest;
  padding: @global-whitespace-medium;
  margin-top: @global-whitespace-base;
}

.saved-content__panel__link__text {
  max-width: 76%;
  overflow-wrap: break-word;
}

.saved-content__panel__link__copy-button {
  color: @global-color-primary;
  align-self: flex-start;
  margin-top: -@global-whitespace-small;

  &:hover {
    color: darken(@global-color-primary, 5%);
  }
}

.saved-content__panel__delete-button {
  color: @global-color-danger;
  justify-self: flex-end;
  margin-top: @global-whitespace-base;

  &:hover {
    color: darken(@global-color-danger, 5%);
  }
}

.saved-content__view-content--mobile {
  display: flex;
  background: @global-color-white;
  border: 1px solid @global-color-gray-lighter;
  border-bottom: 0;
  overflow-y: scroll;
  position: relative;

  @media (min-width: @global-screen-medium-min) {
    display: none;
  }
}

.saved-content-preview__thread-actions--mobile {
  background-color: @global-color-white;
  height: @global-whitespace-xlarge;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  position: relative;
  z-index: @zindex-app-navigation;

  @media (min-width: @global-screen-medium-min) {
    display: none;
  }
}

.saved-content-preview__thread-title--mobile {
  @media (max-width: @global-screen-small-min) {
    padding-top: @global-whitespace-large;
  }
}

.saved-content-preview__app-page {
  @media (max-width: @global-screen-medium-min) {
    padding-top: initial;
  }
}

.saved-content-sending-status__error {
  color: @global-color-danger;
  font-style: italic;
  font-size: @global-font-size-xsmall;
}

.saved-content-sending-status__default {
  font-size: @global-font-size-xsmall;
  color: @global-font-color-muted;
  font-style: italic;
}

.saved-content__h5 {
  line-height: 1.4rem;
}

.filter-dropdown {
  width: 5.9rem;
  font-size: 1.3rem;
  border-color: white;
}

.text-content-center {
  text-align: center;
}

.text-content-left {
  text-align: left;
}
