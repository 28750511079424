
.appointment-reminder-form__box-wrapper {
  max-width: 50rem;
  margin: auto;
}

.appointment-reminder-form__types-button {
  align-self: flex-start;
  justify-content: flex-start;
  width: 12rem;

  span {
    text-align: left;
    justify-content: flex-start;
    font-size: @global-font-size-small;
  }
}

.appointment-reminder-form__types-count {
  width: 12rem;
  text-align: right;
}

.appointment-reminder-form__types-label {
  padding: @global-whitespace-base (@global-whitespace-base + @rhinobox-rhinodio-size + @global-whitespace-xsmall) @global-whitespace-base @global-whitespace-base;
  font-weight: bold;
}

.appointment-reminder-form__types-accent {
  float: right;
}

.appointment-reminder-form__title {
  padding-top: @global-whitespace-large;
  position: relative;
}

.appointment-reminder-form {
  .appointment-type__scroll {
    border-bottom: 1px solid @global-border-color;
    border-top: 1px solid @global-border-color;

    .form__block-group--checkbox {
      border-top: 0;
      border-bottom: 0;
    }
  }

  .template-attachments__attachment {
    display: inline-flex;
    padding: 0 @global-font-size-medium 0 0;
    border: none;
  }

  .template-attachments__remove {
    color: @global-color-gray-darker !important;
  }
}

.appointment-wrapper {
  padding: 20px 0;
}

.appointment__title__section {
  border-top: 1px solid @global-border-color;
  text-align: center;
  padding-top: 20px;
}

.appointment__subtitle {
  font-weight: 100;
}

.appointment-label {
  display: block;
  margin-bottom: 20px;
}
