@rhinovideo-background-color: #303030;

// Modal and default styles
.rhinovideo__loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.rhinovideo__wrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: @rhinovideo-background-color;
}

.rhinovideo__conference-container {
  background-color: @rhinovideo-background-color;
  color: @global-color-white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100%;
  position: relative;

  // Bottom bar
  .rhinovideo__action-bar__container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    bottom: 0;
    width: 100%;
    top: 0;
    z-index: @modal-zindex;

    .rhinovideo__action-bar {
      display: flex;
      background: linear-gradient(180deg, rgba(64, 64, 64, 0) 0%, rgba(64, 64, 64, 0.75) 100%);
      width: 100%;
      justify-content: center;
      padding-bottom: @global-whitespace-base;
    }

    .rhinovideo__action-bar__hangup-button,
    .rhinovideo__action-bar__button {
      transition: none;
      color: @global-font-color;
      border-radius: 50%;
      margin: @global-whitespace-small @global-whitespace-base;
      background-color: @global-color-white;
      z-index: @modal-zindex;

      @media (hover: hover) {
        &:hover {
          transition: 0.2s ease-in-out;
          background-color: @global-color-gray-light;
        }
      }

      &.is-screenshare-enabled {
        border: 1px solid @global-color-accent;
        color: @global-color-accent;
      }

      &.is-panel-open {
        border: 1px solid @global-color-primary;
        color: @global-color-primary;
      }
    }

    .rhinovideo__action-bar__hangup-button {
      background-color: @global-color-danger;
      color: @global-color-white;

      @media (hover: hover) {
        &:hover {
          background-color: @global-color-danger;
          color: @global-color-white;
        }
      }
    }

    .rhinovideo__action-bar__microphone-button {
      height: 1.2em;
    }

    .rhinovideo__action-bar__label {
      font-size: @global-font-size-xsmall;
      text-align: center;
    }
  }

  // Participants
  .rhinovideo__participant-container {
    display: flex;
    align-items: flex-end;
    height: 100%;
    flex-direction: column;

    .rhinovideo__participant {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      position: relative;
      // Stream
      .rhinovideo__participant-stream {
        height: 100%;
        position: absolute;
      }
      // Avatar
      .rhinovideo__participant-avatar {
        width: 300px;
        height: 300px;
        border-radius: 50%;
        position: absolute;
      }

      // is-thumbnail
      &.is-thumbnail {
        position: relative;
        background-color: @rhinovideo-background-color;
        width: 100px;
        height: 100px;
        margin-top: @global-whitespace-base;
        margin-right: @global-whitespace-base;
        border: 1px solid @global-color-gray;
        border-radius: @global-border-radius;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: @modal-zindex;

        .rhinovideo__participant-avatar {
          width: 75px;
          height: 75px;
        }

        .rhinovideo__participant-stream {
          width: 100px;
          height: 100px;
        }
      }

      &.is-pinned {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
      }
    }
  }
  // Mobile Portrait View
  @media only screen and (max-width: @global-screen-xsmall-max) {
    .rhinovideo__action-bar__container {
      .rhinovideo__action-bar__button,
      .rhinovideo__action-bar__hangup-button, {
        margin: @global-whitespace-small;
      }
    }

    .rhinovideo__participant-container {
      justify-content: center;
      align-items: flex-start;
      flex-direction: row;
      // is-thumbnail
      .rhinovideo__participant.is-thumbnail {
        margin-top: @global-whitespace-xlarge;
        margin-left: @global-whitespace-small;
        margin-right: @global-whitespace-small;
      }

      .is-pinned .rhinovideo__participant-avatar {
        width: 200px;
        height: 200px;
      }
    }
  }

  img,
  video {
    pointer-events: none;
  }

  // Timer
  .rhinovideo__timer-container {
    position: absolute;
    z-index: @modal-zindex;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;

    .rhinovideo__timer {
      padding: @global-whitespace-xsmall;
      background-color: @rhinovideo-background-color;
      border-bottom-left-radius: @global-border-radius;
      border-bottom-right-radius: @global-border-radius;
    }
  }
}

// Screenshare
.rhinovideo__screenshare-paused {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: @global-font-size-large;
  display: flex;
  justify-content: center;
  align-items: center;
  background: fade(@rhinovideo-background-color, 90%);
}

// Stream Loading
.rhinovideo__stream-loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: @global-font-size-large;
  display: flex;
  justify-content: center;
  align-items: center;
  background: @rhinovideo-background-color;
}

// Participant Panel
.rhinovideo__participants-panel__container {
  background-color: @global-color-white;
  width: 400px;

  @media only screen and (max-width: @global-screen-small-max) {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: @modal-zindex;
  }

  .rhinovideo__participants-panel__header {
    display: flex;
    align-items: center;
    margin: @global-whitespace-base;
    position: relative;

    .rhinovideo__participants-panel__title {
      font-weight: 700;
      font-size: @global-font-size-large;
    }
  }


  .rhinovideo__participants-panel__item {
    display: flex;
    padding: @global-whitespace-base;
    border-bottom: 1px solid @global-color-gray-lighter;

    &:nth-child(2) {
      border-top: 1px solid @global-color-gray-lighter;
    }

    .rhinovideo__participants-panel__info {
      display: flex;
      flex: 1;
      align-items: center;
    }

    .rhinovideo__participants-panel__avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: @global-whitespace-base;
    }

    .rhinovideo__participants-panel__actions {
      display: flex;
      align-items: center;
    }

    .rhinovideo__participants-panel__actions-dropdown {
      margin-right: @global-whitespace-small;

      &:not(.is-disabled) {
        .icon {
          color: @global-color-primary;
          fill: @app-icon-active-fill-primary;
        }
      }
    }

    .rhinovideo__participants-panel__actions-button {
      margin-right: @global-whitespace-small;

      &:hover,
      &:focus,
      &.is-enabled {
        .icon {
          color: @global-color-primary;
          fill: @app-icon-active-fill-primary;
        }
      }
    }
  }
}

.chrome__app-svg {
  width: 25px;
  height: 25px;
}
