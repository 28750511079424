.search__header {
  margin-bottom: @global-whitespace-small;
}

.search__group {
  & + & {
    margin-top: @global-whitespace-large;
  }

  position: relative;

  &__title {
    margin-bottom: @global-whitespace-base;
    font-size: @global-font-size-base;
  }
}

.search__sub {
  font-size: @global-font-size-small;
  color: @global-font-color-muted;
  margin-bottom: @global-whitespace-small !important;
}

.search__input {
  margin-bottom: @global-whitespace-base;
}

.search__new {
  text-align: center;
  margin-bottom: @global-whitespace-xsmall;
}

.search__modal__lower-body-content {
  display: flex;
  justify-content: space-between;
  font-size: @global-font-size-small;

  @media (max-width: @global-screen-small-max) {
    display: block;
  }
}

.search__modal__selected-count {
  min-width: 64px;
  margin-right: @global-whitespace-base;
  line-height: normal;
}

.search__modal__clear-all-button {
  display: flex;
  align-items: center;
}

.search__modal__clear-all-button-icon {
  margin-right: @global-whitespace-xsmall;
}

.search__modal__lower-body-content-left {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: @global-screen-small-max) {
    width: auto;
    float: left;
  }
}

.search__modal__pills-input {
  max-height: 116px;
  overflow-y: auto;

  @media (max-width: @global-screen-small-max) {
    max-height: 80px !important;
  }
}

.search__max-contact {
  font-size: @global-font-size-small;
  padding: 0 0 @global-whitespace-medium;
  color: @global-color-danger;
}

