.broadcast-modal__container {
  z-index: @modal-zindex + 1;

  &--tags {
    .box {
      padding-top: 0;
      box-shadow: none;
    }

    .tag__modal__create {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;

      button {
        border-radius: 50%;
        padding: @global-whitespace-small;
        height: 2rem;
        width: 2rem;
      }
    }

    .tag__modal__input {
      max-width: 38rem;
    }
  }
}

.broadcast-modal {
  max-width: 67rem;
  width: 100%;

  .resource__wrapper {
    padding-top: @global-whitespace-small;
    padding-bottom: @global-whitespace-small;
  }

  .resource-group__scroll {
    margin-top: 5px;
  }

  .modal__header__title {
    font-size: @global-font-size-large;
    font-weight: @global-font-size-small;
  }

  .variable-message__header {
    margin-top: @global-whitespace-large;
  }

  .modal__body {
    padding-bottom: 0;
    padding-left: 3.4rem;
    padding-right: 3.4rem;
    padding-top: @global-height/2 !important;
  }

  .modal__footer {
    padding-top: 0 !important;

    .grid_class {
      width: 70%;
    }

    .button_grid_class {
      width: 24%;

      @media (max-width: @global-screen-small-max) {
        width: 48%;
      }
    }
  }

  .header__disabled .form__asterisk {
    color: @global-color-gray !important;
  }

  .header__disabled {
    color: @global-color-gray !important;
  }

  .resource__intro__title__sub {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 170px;
  }

  .resource__intro__title__content {
    min-width: 120px;
    max-width: 200px;
  }

  .resource {
    align-items: center;

    &::before,
    &::after {
      top: unset !important;
      transform: unset !important;
      -webkit-transform: unset !important;
    }
  }
}

.broadcast-modal__message-compose {
  border: none;
  background: @global-color-white;
}

.broadcast-modal__header {
  margin-bottom: 0;
}

.broadcast-modal__contacts-count {
  float: right;
}

.savedList-modal__contacts-count {
  float: right;
  margin-bottom: 10px;
}

.broadcast-modal__convo-footer {
  background: @global-color-white;
  border: 1px solid @global-border-color;
  padding: @global-whitespace-medium;
  position: relative;

  &.is-loading {
    border-top-color: transparent;

    > * {
      opacity: 0;
    }
  }
}

.broadcast-modal__header__title {
  font-size: @global-font-size-medium;
  font-weight: 500;
  flex: 1;
}

.broadcast-modal__alert {
  border-radius: 0;
  background: @app-icon-active-fill-primary;
  padding: @global-whitespace-xlarge !important;
  text-align: center;
  border: 0;

  .alert__title {
    justify-content: center;
    font-size: @global-font-size-base;
  }

  .alert__body {
    .u-text-small;

    a {
      .u-text-primary;

      display: inline-block;
      text-decoration: none !important;
    }

    margin-top: @global-whitespace-small;
  }
}

.broadcast-modal__add-button {
  font-size: @global-font-size-large;
  height: @global-height*2;
  padding-left: @global-whitespace-small;
  padding-right: @global-whitespace-small;
}

.broadcast-modal__message {
  flex: 1;
  outline: none;
  padding: 5px 0;
  max-height: @global-height * 2;
  overflow: auto;
}

.broadcast-modal__actions {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  min-height: @global-height/2;
  // 2
  margin: 0 -1px;
  background: @global-color-white;

  > *:not(:last-child) {
    margin-right: @global-whitespace-small;
  }
}

.broadcast-modal__nocontacts {
  .modal__footer {
    text-align: center;
    margin-top: @global-whitespace-medium;
  }

  .modal__body {
    padding-top: 0 !important;
  }

  .broadcast-modal__add-contacts-button {
    margin-top: @global-whitespace-medium;
  }
}

.broadcast-modal__channel__message {
  font-size: 1.4rem;
  margin-top: 0;
}

.broadcast-modal__channels-dropdown {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  > * + * {
    margin-left: @global-whitespace-small;
  }

  // 1
  .dropdown__toggle__text {
    white-space: normal;
  }
}

.broadcast-modal__channel {
  width: 100%;
  margin-left: 0;
  margin-top: @global-whitespace-small;

  .dropdown {
    width: 100%;
  }

  .button__text-wrapper {
    display: inline;
  }

  .dropdown__toggle__text {
    text-align: left;
    font-weight: @global-font-weight-normal;
    line-height: 1;
  }

  .button {
    background: @global-color-white;
    float: right;
    width: 45%;

    @media (max-width: @global-screen-small-max) {
      width: 60%;
    }
  }

  p {
    display: block;
  }

  svg {
    float: right;
    margin-top: 14px;
  }
}

.broadcast-modal__channel-HIPAA {
  width: 100%;
  margin-left: 0;
  margin-top: @global-whitespace-small;

  .dropdown {
    width: 100%;
  }

  .button__text-wrapper {
    display: inline;
  }

  .dropdown__toggle__text {
    text-align: left;
    font-weight: @global-font-weight-normal;
    line-height: 1;
  }

  .button {
    background: @global-color-white;
    float: right;
    width: 100%;

    @media (max-width: @global-screen-small-max) {
      width: 60%;
    }
  }

  p {
    display: block;
  }

  svg {
    float: right;
    margin-top: 14px;
  }
}

.hipaa-modal__dropdown-label {
  color: @global-color-danger;
}

.broadcast-modal__message__attachments {
  padding: 0 @global-whitespace-base @global-whitespace-base @global-whitespace-base;

  .is-note & {
    background: @msg-note-bg;
  }
}

.broadcast-modal__button-description {
  font-size: @global-font-size-small;
  margin: 0;
  padding-top: 2px;
}

.broadcast-channel__dropdown {
  width: 100%;
  text-align: left;

  .button__text-wrapper .svg {
    margin-top: 15px;
    float: right;
  }
}
