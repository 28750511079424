//
// Emoji-Mart Picker wrapper
//

.emoji-picker {
  * {
    width: 338px;

    --shadow: none;
  }
}

.emoji-picker__dropdown__wrapper {
  display: flex;
  color: @global-font-color-muted;
  font-size: @global-font-size-large * 1.15;
  cursor: pointer;

  &:hover {
    color: @global-color-primary;
  }
}

.emoji-picker__dropdown__wrapper--convo-thread {
  display: flex;
  color: @global-font-color-muted;
  font-size: @global-font-size-large * 1.15;
  cursor: pointer;

  &:hover {
    color: @global-color-primary;
  }

  .dropdown__menu {
    min-width: 340px;
    left: -@global-whitespace-base;
    bottom: 89px;

    &--top {
      &::before {
        bottom: 1rem;
      }
    }
  }
}
