.rhinophone-iframe__wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  iframe {
    width: 100%;
    height: 100%;
  }
}
