.character-count {
  .u-text-small;

  color: @global-font-color-muted;
  text-align: right;
  margin-left: auto;
  cursor: default;
}

.character-count--warning {
  color: @global-color-accent;
  font-weight: @global-font-weight-bold;
}

.character-count__convo-channels {
  @media (max-width: @global-screen-xlarge-max) {
    visibility: hidden;
  }

  @media (min-width: @global-screen-xlarge-min) {
    visibility: visible;
  }
}

.character-count__compose-actions {
  @media (max-width: @global-screen-xlarge-max) {
    visibility: visible;
  }

  @media (min-width: @global-screen-xlarge-min) {
    visibility: hidden;
  }
}
