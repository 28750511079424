.contact-library__nav-tab {
  padding: (@global-whitespace-small / 2) @global-whitespace-base;
}

.contact-library__saved-content__item-details__wrapper {
  &:not(:first-child) {
    & .bucket.bucket--default {
      border-top: none;
    }
  }

  & .bucket {
    border-radius: 0;

    .bucket__body {
      border-radius: 0;
    }
  }
}

.contact-library__saved-content__item-details__title {
  color: @global-font-color;
  font-weight: @global-font-weight-bold;
  font-size: @global-font-size-small;
}

.contact-library__saved-content__item-details__note {
  color: @global-font-color;
  font-size: @global-font-size-small;
  margin-bottom: @global-whitespace-small;
}

.contact-library__saved-content__item-details__timestamp {
  font-size: @global-font-size-xsmall;
  color: @global-color-gray;
}

.contact-library__form__title {
  color: @global-font-color;
  font-weight: @global-font-weight-bold;
}

.contact-library__form__wrapper {
  padding-left: @global-whitespace-small;
  margin-top: @global-whitespace-medium;

  .contact-library__form__send {
    color: @global-color-gray;
    font-weight: @global-font-weight-bold;
    font-size: @global-font-size-small;
  }

  .contact-library__form__send-button {
    margin-top: @global-whitespace-large;
    float: right;
  }

  .contact-library__form__divider {
    border-top: 1px solid @global-border-color;
    margin: @global-whitespace-base 0;
  }
}
