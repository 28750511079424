.profile__data__phone__box {
  color: @global-font-color;
  background: @global-color-panel;
  border: 1px solid @global-border-color;
  font-size: @global-font-size-small;

  // Override default radio block group styles to "butt-up" against container
  // Doing it this way instead of no padding since we have a footer area for confirmation
  .form__block-group--radio {
    border: 0;
  }
}

// 1. Override default label styles
// 2. Style
.profile__data__phone__label {
  // 1
  display: block;
  margin-bottom: 0;
  // 2
  padding: @global-whitespace-base;
  border-bottom: 1px solid @global-border-color;
}

.profile__data__opted-out-label {
  .u-text-small;

  background-color: @global-color-gray-lighter;
  padding: @global-whitespace-xsmall;
}

.profile__data__phone__box__footer {
  border-top: 1px solid @global-border-color;
  padding: @global-whitespace-base;
  text-align: right;
}

// Override default cursor since we don't have a clickable action on the entire resource
.profile__connected-party {
  cursor: default;
}

.profile__forms__expand {
  float: right;
}

// 1. Fudge the close width + whitespace to align with select
.profile__connected-parties__label {
  @media (min-width: @global-screen-small-min) {
    text-align: right;
    padding-right: (@global-font-size-small + @global-whitespace-small + @global-whitespace-base); // 1
  }
}

.is-minor {
  color: @global-color-danger;
}
