.mixin-filter-notify() {
  &.has-filter {
    position: relative;

    &::after {
      content: '';
      width: 12px;
      height: 12px;
      background: @global-color-danger;
      border-radius: 50%;
      position: absolute;
      border: 2px solid @app-background-color;
      top: 4px;
      right: -4px;
      z-index: 2;
    }
  }
}
