//
// Component
//


.app-lazy-loader {
  bottom: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  z-index: 1;
}


.app-lazy-loader__inner {
  align-items: center;
  background: @global-color-white;
  box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.25);
  display: flex;
  left: 50%;
  padding: @global-whitespace-base @global-whitespace-base @global-whitespace-small;

  .loader-circle {
    margin-right: @global-whitespace-small;
  }
}
