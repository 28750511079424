.ql-editor {
  white-space: pre-wrap;

  > p {
    margin: 0;
  }
}

.ql-editor.ql-blank::before {
  content: attr(data-placeholder);
  pointer-events: none;
  position: absolute;
  font-size: @global-font-size-base;
  color: @input-color-placeholder;
}

.ql-editor:focus {
  outline: none;
}

.ql-clipboard {
  position: absolute;
}

.ql-variable {
  display: inline;
  pointer-events: none;
  color: @global-color-primary;
  margin-bottom: 0.1rem; // 2
  cursor: pointer;
  vertical-align: baseline;

  // Hide squigglies
  i {
    display: none;
  }

  // Do not show underscore
  b {
    opacity: 0;
    width: 0.3rem;
    font-size: 1rem;
  }

  div {
    cursor: text;
    display: inline-block;
  }

  > span {
    margin-right: 0.1px; // DO NOT REMOVE!!! Arbitrary margin right needed for Firefox focus click
    margin-left: 0.1px; // DO NOT REMOVE!!! Arbitrary margin left needed for Firefox focus click
  }
}
