.template-modal__container {
  z-index: @modal-zindex + 2;

  .template-modal {
    max-width: 80rem;
    width: 100%;

    @media (min-width: @global-screen-small-max) {
      min-height: 58rem;
    }
  }

  .list-panel__body__scroll {
    height: 45vh !important;
    margin-top: 1.2rem;
    border-top: none;

    @media (max-width: @global-screen-small-max) {
      height: 30vh !important;
    }
  }

  .rt-thead {
    z-index: 0;
  }

  .dropdown__menu__item {
    z-index: 4;
  }

  .search__input .form__addon__item {
    border: none;
  }

  .search-filter-wrapper .form__group.search__input .input__wrapper input {
    border: 1px none;
  }

  .app-page__header {
    z-index: 4;
  }

  .rt-resizable-header {
    &:last-of-type {
      padding: 0;
    }
  }
}

.template-attachments__attachment {
  display: flex;
  align-items: center;
}

.template-attachments__name {
  flex: 1;
}

.template-attachments__remove {
  margin-left: @global-whitespace-small;
}

.template-attachments__footer {
  margin-top: @global-whitespace-large;
  font-size: @global-font-size-small;
}

.template-attachments__footer__row {
  display: flex;

  & + & {
    margin-top: @global-whitespace-small;
  }
}

.template-attachments__footer__status {
  flex: none;
  margin-right: @global-whitespace-small;
}

.template__favorite-trigger {
  &.is-favorited .icon-stroke {
    color: @global-color-danger;
    fill: @global-color-danger-light;
  }
}

.transform-variables {
  color: @global-color-primary;
  border-color: @global-color-primary;
}

.template-form__table {
  border-bottom: 1px solid @global-border-color;

  th {
    border-top: 0;
    vertical-align: bottom;
    padding-bottom: @global-whitespace-small;

    &:first-child {
      padding-left: 0;
      width: 100%;
    }

    &:not(:first-child) {
      text-align: center;
      text-transform: none;
    }
  }

  tbody {
    tr {
      border-left: 1px solid @global-border-color;
      border-right: 1px solid @global-border-color;
    }
  }

  td {
    &:first-child {
      width: 100%;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  & + & {
    margin-top: @global-whitespace-base;
  }

  &--reminders {
    .template-form__table__header,
    tr {
      td {
        &:first-child {
          width: 100%;
        }

        &:last-child {
          padding: 0 @global-whitespace-base 0;
          vertical-align: middle;

          button {
            float: right;
            padding: 0;
          }
        }
      }
    }

    @media (max-width: @global-screen-small-max) {
      font-size: @global-font-size-small;
    }
  }
}

.template-form__table__header {
  white-space: nowrap;
}

.template-form__table__data-description {
  text-indent: @global-whitespace-small;
}

.template-form__table__data-category {
  font-size: @global-font-size-base;
}

.template-modal__document {
  .rt-thead {
    z-index: 0;
  }

  .rt-td {
    height: 45px;
  }

  .close {
    z-index: 1;
  }

  .list-panel__body__scroll {
    height: 42vh !important;
    margin-top: 1.2rem;
    border-top: none;
  }

  .title-text {
    position: relative;
    bottom: 5px;
  }

  .title-draft-text {
    color: @global-color-accent;
    font-size: @global-font-size-small;
    margin: 0;
    position: absolute;
    bottom: 1px;
  }

  .modal__header__title-wrapper {
    position: relative;
  }

  @media (max-width: @global-screen-small-max) {
    .title-text {
      bottom: 2px;
    }

    .title-draft-text {
      bottom: 1px;
      font-size: @global-font-size-xsmall;
    }
  }

  .nav-tabs {
    justify-content: center;
    position: absolute;
    top: -20px;
    width: 100%;
    left: 0;

    .nav-tabs__item__link {
      font-size: @global-font-size-large;
      color: @global-color-gray-darker;

      &::after {
        display: none;
      }
    }
  }

  .nav-tabs__item {
    &::before {
      border: none;
    }

    &.is-active {
      .nav-tabs__item__link {
        color: @global-color-primary;
        font-weight: bold;
      }

      &::after,
      &::before {
        border-left: none;
        border-right: none;
      }

      &::before {
        transform: none;
        -webkit-transform: none;
        border-bottom: solid 3px @global-color-primary;
      }
    }
  }

  .tabs-content {
    padding: 30px 0 0;
  }

  .search__input .form__addon__item {
    border: none;
  }
}

.template-modal__header-button {
  border-radius: 50%;
  position: absolute;
  left: 0;
}

.vCard-modal__header-checkbox {
  padding-left: 1px;
}

.form-list {
  span {
    font-size: @global-font-size-medium;
    padding: 0 10px 10px 0;
    display: inline-block;
  }
}

.app-page__container--template {
  .template-attachments__attachment {
    display: inline-flex;
    padding: 0 @global-font-size-medium 0 0;
    border: none;
  }

  .template-attachments__remove {
    color: @global-color-gray-darker !important;
  }
}

.template-model-checkbox {
  font-size: 0;
}
