// Used for "empty" areas across the app
// Will be expanded with variations

.empty-message__wrapper {
  text-align: center;
  margin: auto;
}

.empty-message__content {
  padding: @global-whitespace-base;
  max-width: 800px;
  margin: auto;
}

.empty-message__icon {
  color: @global-color-primary;
  fill: @app-icon-active-fill-primary;
  width: 100px;
  height: 100px;
}

.empty-message__inline-svg {
  width: 250px;

  @media only screen and (max-width: @global-screen-small-max) {
    width: 175px;
  }
}

.empty-message__footer {
  color: @global-color-gray;
  font-size: @global-font-size-small;
  padding: @global-whitespace-base;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
