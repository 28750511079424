//
// Variables
//


@app-navigation-bg: @global-color-primary;
@app-navigation-active: tint(@global-color-primary, 85%);

@app-nav-width-large: 24rem;
@app-nav-icon-size: @global-font-size-large;
@app-sidebar-width: @app-header-height;
@app-secure-avatar-size: 3rem;
@app-navigation-border-radius: (@global-border-radius * 4);
@app-header-width: 60px;
@app-nav-width-small: 20rem;
@app-navigation-phone-bg: #0bf;
@app-navigation-phone-active: rgba(0, 159, 217, 1);

//
// Component
//


// 1. Fix for IE11 not respecting `min-height` with flexbox
.app-navigation {
  background-color: @global-color-white;
  bottom: 0;
  display: flex; // 1
  flex-direction: column; // 1
  position: fixed;
  top: 0;
  left: @app-sidebar-width;
  width: @app-nav-width-large;
  z-index: @zindex-app-navigation;
  border-top-left-radius: @app-navigation-border-radius;
  border-bottom-left-radius: @app-navigation-border-radius;
  border-right: 1px solid @global-color-gray-lighter;

  @media (max-width: @global-screen-nano-min - 40) {
    max-width: @app-nav-width-small;
  }
}

.app-navigation-sidebar {
  transform: translateX(-(@app-sidebar-width +  @app-nav-width-large));
  background-color: @app-navigation-bg;
  bottom: 0;
  display: flex; // 1
  flex-direction: column; // 1
  position: fixed;
  top: 0;
  width: (@app-sidebar-width +  @app-nav-width-large);
  align-items: flex-start;
  justify-content: space-between;
  z-index: @zindex-app-navigation;

  @media (min-width: @global-screen-medium-min) {
    transform: translateX(0);
    height: 100%;
    overflow: auto;
  }

  @media (max-width: @global-screen-nano-min - 40) {
    max-width: @app-nav-width-large;
  }

  &.app-navigation-sidebar--settings {
    background-color: @settings-active-navbar;
  }

  &.app-navigation-sidebar--managers {
    background-color: @managers-active-navbar;
  }

  &.app-navigation-sidebar--phone {
    background-color: @app-navigation-phone-bg;
  }

  &--phone {
    .app-navigation__sidebar__button {
      &.phone,
      &.is-active {
        background: @app-navigation-phone-active !important;
        opacity: 1;
      }

      &:hover {
        @media (min-width: @global-screen-large-min) {
          background: @app-navigation-phone-active !important;
          opacity: 1;
        }
      }
    }

    .app-navigation__nav__button {
      &.phone,
      &.is-active,
      &:hover,
      &:focus {
        background: rgba(7, 150, 134, 0.15);
      }
    }
  }

  &--managers {
    background-color: @managers-active-navbar;

    .app-navigation__sidebar__button {
      &.managers,
      &.is-active {
        background: @managers-active-navbar-icon !important;
        opacity: 1;
      }

      &:hover {
        @media (min-width: @global-screen-large-min) {
          background: @managers-active-navbar-icon !important;
          opacity: 1;
        }
      }
    }

    .app-navigation__nav__button {
      &.managers,
      &.is-active,
      &:hover,
      &:focus {
        background: @managers-active-nav-link;
      }
    }

    .app-navigation__nav__button__text {
      color: @managers-active-navbar-icon !important;
      font-size: @global-font-size-base !important;
    }

    .accessibe__button {
      &:hover {
        @media (min-width: @global-screen-large-min) {
          background: @managers-active-navbar-icon !important;
        }
      }
    }

    .app-nav__multi-org {
      background: @managers-active-navbar-icon !important;
    }
  }

  &--analytics {
    background-color: @analytics-active-navbar;

    .app-navigation__sidebar__button {
      &.analytics,
      &.is-active {
        background: @analytics-active-navbar-icon !important;
        opacity: 1;
      }

      &:hover {
        @media (min-width: @global-screen-large-min) {
          background: @analytics-active-navbar-icon !important;
          opacity: 1;
        }
      }
    }

    .accessibe__button {
      &:hover {
        @media (min-width: @global-screen-large-min) {
          background: @analytics-active-navbar-icon !important;
        }
      }
    }

    .app-navigation__nav__button {
      &.analytics,
      &.is-active,
      &:hover,
      &:focus {
        background: @analytics-active-nav-link;
      }
    }

    .app-navigation__nav__button__text {
      color: @analytics-active-navbar-icon !important;
      font-size: @global-font-size-base !important;
    }

    .app-nav__multi-org {
      background: @analytics-active-navbar-icon !important;
    }
  }

  &--contacts {
    background-color: @contacts-active-navbar;

    .app-navigation__sidebar__button {
      &.contacts,
      &.is-active {
        background: @contacts-active-navbar-icon !important;
        opacity: 1;
      }

      &:hover {
        @media (min-width: @global-screen-large-min) {
          background: @contacts-active-navbar-icon !important;
          opacity: 1;
        }
      }
    }

    .accessibe__button {
      &:hover {
        @media (min-width: @global-screen-large-min) {
          background: @contacts-active-navbar-icon !important;
        }
      }
    }

    .app-navigation__nav__button {
      &.contacts,
      &.is-active,
      &:hover,
      &:focus {
        background: @contacts-active-nav-link;
      }
    }

    .app-navigation__nav__button__text {
      color: @contacts-active-navbar !important;
      font-size: @global-font-size-base !important;
    }

    .app-nav__multi-org {
      background: @contacts-active-navbar-icon !important;
    }
  }
}


.app-navigation-sidebar-limited {
  transform: translateX(-(@app-sidebar-width));
  background-color: @app-navigation-bg;
  bottom: 0;
  display: flex; // 1
  flex-direction: column; // 1
  position: fixed;
  top: 0;
  width: (@app-sidebar-width);
  align-items: flex-start;
  justify-content: space-between;
  z-index: @zindex-app-navigation;

  @media (min-width: @global-screen-medium-min) {
    transform: translateX(0);
    height: 100%;
    overflow: auto;
  }

  @media (max-width: @global-screen-nano-min - 40) {
    max-width: @app-nav-width-large;
  }

  &.app-navigation-sidebar--settings {
    background-color: @settings-active-navbar;
  }

  &.app-navigation-sidebar--managers {
    background-color: @managers-active-navbar;
  }

  &.app-navigation-sidebar--phone {
    background-color: @app-navigation-phone-bg;
  }

  &--phone {
    .app-navigation__sidebar__button {
      &.phone,
      &.is-active {
        background: @app-navigation-phone-active !important;
        opacity: 1;
      }

      &:hover {
        @media (min-width: @global-screen-large-min) {
          background: @app-navigation-phone-active !important;
          opacity: 1;
        }
      }
    }

    .app-navigation__nav__button {
      &.phone,
      &.is-active,
      &:hover,
      &:focus {
        background: rgba(7, 150, 134, 0.15);
      }
    }
  }

  &--managers {
    background-color: @managers-active-navbar;

    .app-navigation__sidebar__button {
      &.managers,
      &.is-active {
        background: @managers-active-navbar-icon !important;
        opacity: 1;
      }

      &:hover {
        @media (min-width: @global-screen-large-min) {
          background: @managers-active-navbar-icon !important;
          opacity: 1;
        }
      }
    }

    .app-navigation__nav__button {
      &.managers,
      &.is-active,
      &:hover,
      &:focus {
        background: @managers-active-nav-link;
      }
    }

    .app-navigation__nav__button__text {
      color: @managers-active-navbar-icon !important;
      font-size: @global-font-size-base !important;
    }

    .accessibe__button {
      &:hover {
        @media (min-width: @global-screen-large-min) {
          background: @managers-active-navbar-icon !important;
        }
      }
    }

    .app-nav__multi-org {
      background: @managers-active-navbar-icon !important;
    }
  }

  &--analytics {
    background-color: @analytics-active-navbar;

    .app-navigation__sidebar__button {
      &.analytics,
      &.is-active {
        background: @analytics-active-navbar-icon !important;
        opacity: 1;
      }

      &:hover {
        @media (min-width: @global-screen-large-min) {
          background: @analytics-active-navbar-icon !important;
          opacity: 1;
        }
      }
    }

    .accessibe__button {
      &:hover {
        @media (min-width: @global-screen-large-min) {
          background: @analytics-active-navbar-icon !important;
        }
      }
    }

    .app-navigation__nav__button {
      &.analytics,
      &.is-active,
      &:hover,
      &:focus {
        background: @analytics-active-nav-link;
      }
    }

    .app-navigation__nav__button__text {
      color: @analytics-active-navbar-icon !important;
      font-size: @global-font-size-base !important;
    }

    .app-nav__multi-org {
      background: @analytics-active-navbar-icon !important;
    }
  }

  &--contacts {
    background-color: @contacts-active-navbar;

    .app-navigation__sidebar__button {
      &.contacts,
      &.is-active {
        background: @contacts-active-navbar-icon !important;
        opacity: 1;
      }

      &:hover {
        @media (min-width: @global-screen-large-min) {
          background: @contacts-active-navbar-icon !important;
          opacity: 1;
        }
      }
    }

    .accessibe__button {
      &:hover {
        @media (min-width: @global-screen-large-min) {
          background: @contacts-active-navbar-icon !important;
        }
      }
    }

    .app-navigation__nav__button {
      &.contacts,
      &.is-active,
      &:hover,
      &:focus {
        background: @contacts-active-nav-link;
      }
    }

    .app-navigation__nav__button__text {
      color: @contacts-active-navbar !important;
      font-size: @global-font-size-base !important;
    }

    .app-nav__multi-org {
      background: @contacts-active-navbar-icon !important;
    }
  }
}

.app-navigation__logo {
  display: flex;
  height: @app-header-height;
  align-items: center;
  padding: 0 @global-whitespace-small;
  width: @app-header-width;
}

.app-navigation__bottom {
  flex: none;
  margin-top: @global-whitespace-large;
  text-align: center;

  &:empty {
    display: none;
  }
}

.app-navigation__org {
  padding: @global-whitespace-base;
  color: @global-font-color-muted;
  word-break: break-word;

  span {
    font-size: @global-font-size-xsmall;
    display: block;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}

.app-nav__multi-org {
  background: @global-color-info;
  color: @global-color-white;
  padding: @global-whitespace-small;
  display: block;
  font-size: @global-font-size-small;
  width: 100%;
}


//
// App Navigation Container
//

.app-navigation__container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  flex: 1;
  justify-content: space-between;

  .no-touchevents & {
    overflow-y: overlay;
  }
}

//
// App Navigation Nav
//


.app-navigation__nav {
  list-style: none;
  margin: @global-whitespace-base 0 0;
  padding: 0;
  position: relative;
  z-index: 5;

  .app-navigation__nav__button__text {
    word-break: break-word;
  }
}

.app-navigation__nav__header,
.app-navigation__nav__button {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: @global-whitespace-small @global-whitespace-base;
  color: @global-color-white;
}

.app-navigation__sub-nav__text {
  color: @settings-subnav-text;
  font-size: @global-font-size-small;
  font-weight: @global-font-weight-bold;
}

.app-navigation__nav__header {
  .app-navigation__nav__button__text {
    color: @global-color-primary;
    font-size: @global-font-size-small;
    text-transform: uppercase;
    font-weight: @global-font-weight-bold;
    line-height: @global-line-height-small;
  }

  .app-navigation__nav__header__text {
    color: @global-color-primary-hover;
    font-size: @global-font-size-base;
    text-transform: uppercase;
    font-weight: @global-font-weight-bold;
    line-height: @global-line-height-small;
  }
}

.app-navigation__sidebar__button {
  position: relative;
  color: @global-color-white;
  font-size: @app-icon-toggle-size;
  display: flex;
  justify-content: center;
  padding: @global-whitespace-base;
  cursor: pointer;

  &:hover {
    @media (min-width: @global-screen-large-min) {
      background-color: mix(@app-navigation-bg, #000, 92.5%);
      opacity: 1;
    }
  }

  .icon {
    opacity: 0.65;
  }

  &:focus,
  &.is-active {
    background-color: mix(@app-navigation-bg, #000, 85%) !important;
    opacity: 1;

    .icon {
      opacity: 1;
    }
  }

  &.is-active {
    font-weight: @global-font-weight-bold;
  }
}

.accessibe__button {
  color: @global-color-white;
  font-size: @app-icon-toggle-size;
  padding: @global-whitespace-base;
  cursor: pointer;

  &:hover {
    @media (min-width: @global-screen-large-min) {
      background-color: mix(@app-navigation-bg, #000, 92.5%);
      opacity: 1;
    }
  }

  .accessibe__icon {
    width: @app-icon-toggle-size;
  }
}

.app-navigation__sidebar__button__video {
  flex-direction: column;
  position: relative;
  color: @global-color-white;
  font-size: @app-icon-toggle-size;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: @global-whitespace-small;
  cursor: pointer;
  border-radius: 50%;
  width: 45px;
  margin: auto;
  margin-top: @global-whitespace-small;
  height: 45px;
}

.app-navigation__sidebar__button__video-status {
  font-size: (@global-font-size-base - 0.6rem);
  text-align: center;
  color: @global-color-white;
  margin: auto;
  margin-top: @global-whitespace-xsmall;
}

.app-navigation__nav__sub {
  a {
    color: @global-font-color;
    padding-left: @global-whitespace-large;

    &:focus,
    &.is-active {
      background-color: @app-navigation-active;
    }

    &.is-active {
      font-weight: @global-font-weight-bold;
    }
  }

  &:hover {
    background-color: @app-navigation-active;
  }
}

.app-navigation__nav__button__badge {
  background: @global-color-primary;
  border-radius: (@global-line-height-computed / 2);
  color: @global-color-white;
  font-size: @global-font-size-small;
  line-height: @global-line-height-computed;
  min-width: @global-line-height-computed;
  margin-left: auto;
  text-align: center;
  padding: 0 @global-whitespace-xsmall;
  height: @global-line-height-computed;

  &:empty {
    display: none;
  }
}

.app-navigation-sidebar--settings {
  .app-navigation__sidebar__button {
    &.is-active {
      background: @settings-active-navbar-icon !important;
    }

    &:hover {
      @media (min-width: @global-screen-large-min) {
        background: @settings-active-navbar-icon !important;
      }
    }
  }

  .accessibe__button {
    &:hover {
      @media (min-width: @global-screen-large-min) {
        background: @settings-active-navbar-icon !important;
      }
    }
  }

  .app-navigation__nav__button {
    &.is-active,
    &:hover,
    &:focus {
      background: @settings-active-nav-link;
    }
  }

  .app-nav__multi-org {
    background: @settings-active-navbar-icon !important;
  }

  .app-navigation__nav__button__text {
    color: @settings-active-navbar-icon;
    font-size: @global-font-size-base;
  }
}

.app-navigation__sidebar__button__unread-counts {
  font-size: @global-font-size-xsmall;
  position: absolute;
  right: 7px;
  top: 7px;
  padding: 0 2px;
  min-width: 18px;
  min-height: 18px;
  background: @global-color-danger;
  text-align: center;
  border-radius: 50%;
  z-index: 1;
}

.app-navigation__list-item__divider {
  width: 100%;
  border-bottom: 1px solid @global-color-gray-lighter;
  margin-top: @global-whitespace-xsmall;
}
