//
// Component
//
@msg-border-radius: 1.125em; // 18px
@msg-line-height: inherit;
@msg-note-bg:#fdf9c7;

.inbox__item__body__failed-icon {
  margin-right: @global-whitespace-xsmall;
  color: @global-state-danger-text;
  font-size: 2.5rem;
}

.inbox__item__body__arrow-right {
  font-size: 1.1rem;
  margin-right: @global-whitespace-xsmall;
}

.inbox__item {
  .resource__intro__media {
    .icon-stroke {
      stroke: @global-font-color-muted;
    }
  }

  .mention-line-break {
    display: none;
  }
}

.inbox__item--following {
  .resource__intro__media {
    svg {
      fill: fadeout(@global-color-warning, 80%);
    }

    .icon-stroke {
      stroke: @global-color-warning;
    }
  }
}

.inbox__item__body--flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.bulk-select-header {
  .dropdown__menu--right {
    min-width: 22rem;
  }

  .is-disabled {
    .dropdown__menu__item__content__desc {
      color: @global-state-danger-text;
    }
  }
}

.convo__footer__nav {
  .dropdown__menu__scroll {
    max-height: 20rem;
  }
}

@media (max-width: @global-screen-small-min) {
  .inbox-pagination__count {
    padding-left: @global-whitespace-base;
  }
}

@media (max-width: @global-screen-xsmall-max) {
  .inbox__item {
    .inbox__chat-item__body__arrow-right {
      display: none;
    }

    .mention-line-break {
      display: inherit;
    }
  }
}

.inbox-pagination__title {
  .u-text-muted;
  .u-m-l;
  .u-m-r;

  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
}


.convo__start-button__wrapper {
  margin: 0 @global-whitespace-small;
  display: flex;
  justify-content: center;
  flex-direction: row;

  .convo__start-button {
    margin: 0 @global-whitespace-small;
  }

  @media (max-width: @global-screen-xsmall-max) {
    justify-content: space-between;
    text-align: center;

    &--multiple {
      min-width: 10rem;
    }

    .convo__start-button {
      margin: 0;
    }
  }
}

.msg--attachment {
  font-style: italic;
}

.inbox__item__attachment-icon {
  height: 3em;
  width: 1.5em;
  margin-left: 5px;
}
