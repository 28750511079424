//
// Component
//


// Header area
// Contains switcher
.member-preferences__header {
  @media (min-width: @global-screen-small-min) {
    display: flex;
  }
}

.member-preferences__header__left {
  @media (min-width: @global-screen-small-min) {
    margin-right: @grid-gutter;
  }
}

.member-preferences__header__right {
  @media (max-width: @global-screen-small-max) {
    margin-top: @grid-gutter;
  }

  @media (min-width: @global-screen-small-min) {
    margin-left: auto;
  }
}

// App store badges
.member-preferences__app-svg {
  width: 135px;
  height: 40px;
}

// Features a number of overrides to our standard table
.member-preferences__table {
  border-bottom: 1px solid @global-border-color;

  th {
    border-top: 0;
    vertical-align: bottom;
    padding-bottom: @global-whitespace-small;

    &:first-child {
      padding-left: 0;
      width: 100%;
    }

    &:not(:first-child) {
      text-align: center;
      text-transform: none;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  tbody {
    tr {
      border-left: 1px solid @global-border-color;
      border-right: 1px solid @global-border-color;
    }
  }

  td {
    &:first-child {
      width: 100%;
    }

    &:not(:first-child) {
      text-align: center;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  // Override checkboxes to horizontally center them
  // 1. Checkboxes have a small offset for labels - since we don't have them here, we can kill it
  .form__group {
    display: inline-flex;
    vertical-align: top;
    margin-left: @global-whitespace-xsmall;
  }

  & + & {
    margin-top: @global-whitespace-base;
  }
}

.member-preferences__table__icon {
  display: block;
  margin: 0 auto @global-whitespace-xsmall auto !important;
}

.member-preferences__group-sub {
  background: @global-color-panel;
  word-break: break-word;
}
