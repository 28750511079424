// Preferences
.block-preference {
  @media (min-width: @global-screen-small-min) {
    display: flex;
  }

  & + & {
    margin-top: @global-whitespace-large;
    padding-top: @global-whitespace-large;
    border-top: 1px solid @global-border-color;
  }
}

// When an org preference does not have a description, use this modifier to vertically center label and switcher
.block-preference--no-desc {
  @media (min-width: @global-screen-small-min) {
    align-items: center;
  }
}

.block-preference__left {
  @media (min-width: @global-screen-small-min) {
    flex: 1;
    margin-right: @global-whitespace-base;
  }
}

.block-preference__name {
  margin: 0;

  &:only-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  @media (max-width: @global-screen-small-max) {
    font-size: @global-font-size-base;
  }
}

.block-preference__desc {
  margin-top: @global-whitespace-xsmall;
  font-size: @global-font-size-small;
  color: @global-font-color-muted;
}

.block-preference__desc_unmuted {
  margin-top: @global-whitespace-xsmall;
  font-size: @global-font-size-small;
  color: @global-font-color;
}

.block-preference__right {
  @media (min-width: @global-screen-small-min) {
    flex: none;
  }

  @media (max-width: @global-screen-small-max) {
    margin-top: @global-whitespace-small;
  }
}

.session-timeout__wrapper {
  @media (min-width: @global-screen-small-min) {
    display: flex;
    justify-content: center;
  }
}

.session-timeout__label-wrapper {
  @media (min-width: @global-screen-small-min) {
    text-align: right !important;
    margin-top: (@global-height - @global-line-height-computed) / 2;
    margin-right: @form-group-spacing;
  }

  @media (max-width: @global-screen-small-max) {
    margin-bottom: @global-whitespace-small;
  }
}

.session-timeout__input-wrapper {
  @media (min-width: @global-screen-small-min) {
    width: 30rem;
  }
}

.office-vCard {
  position: relative;

  .office-vCard__title {
    height: 3.2rem;
    align-items: center;
    padding: 0 8rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .office-vCard__add {
    justify-self: flex-end;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%;
  }

  .office-vCard__collapse {
    position: absolute;
    right: 0;
    width: 8rem;

    .icon-stroke {
      stroke-width: 2;
    }
  }

  .office-vCard__phone {
    .dropdown,
    .dropdown__multi-select {
      width: 100%;
    }
  }
}

.office-alert {
  background: #fee9d1;
  border: 1px solid #ff8300;
}

.office-alert svg {
  color: #ff8300;
}

.appointment-reminder__wrapper {
  @media (min-width: @global-screen-small-min) {
    display: flex;
    flex-direction: column;
  }
  // Affecting adjacent elements of either class
  & + &,
  & + .block-preference,
  & + .appointment-reminder__wrapper,
  .block-preference + &,
  .appointment-reminder__wrapper + & {
    margin-top: @global-whitespace-large;
    padding-top: @global-whitespace-large;
    border-top: 1px solid @global-border-color;
  }
}
