//
// Component
//

.edit-profile__avatar-wrapper {
  margin-bottom: @global-whitespace-large;
  text-align: center;
}

.edit-profile__avatar {
  display: block;
  margin: 0 auto @global-whitespace-base auto;
}

.edit-profile__phones__add-button,
.edit-profile__emails__add-button {
  font-size: @global-font-size-small;
}

// Manual
.edit-profile__close-button__column {
  align-self: flex-end;

  .edit-profile__close-button__icon {
    height: @global-height;
  }
}

.edit-profile__phone__opted-out-label {
  .u-text-small;

  background-color: @global-color-gray-lighter;
  padding: 1rem;
  margin-right: -@global-whitespace-medium;
  border-radius: 0 @global-border-radius @global-border-radius 0;
}

.edit-profile__allow-write-backs {
  @media (min-width: @global-screen-small-min) {
    align-self: center;
    padding-top: @global-whitespace-xlarge;
    padding-left: @global-whitespace-large;
  }
}
