//
// Variables
//


@app-overlay-opacity: 70%;
@app-nav-wrapper-width: 20rem;


//
// Component
//


.app-overlay {
  background: fade(@global-color-black, @app-overlay-opacity);
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  cursor: pointer;
  display: none;
  z-index: @zindex-app-overlay;
}

.app-overlay__close {
  color: @global-color-white;
  position: absolute;
  top: 5px;
  left: 90%;
  font-weight: bold;
  cursor: pointer;
  opacity: 1;
}
