//
// Component
//


// 1. Override `data-vh-calculated` inline style
.signup {
  background: @global-color-gray-lightest;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  // 1
}

.signup__intro-text {
  font-weight: @global-font-weight-bold;
  text-align: center;
  margin-bottom: (@global-whitespace-large * 2);
}


//
// Secure App Content Body
//


.signup__content {
  flex: 1 0 auto;
}

.signup__content__container {
  &:extend(.app-page__container__inner--large);

  margin: 0 auto;
  padding: @global-whitespace-base;

  @media (min-width: @global-screen-small-min) {
    padding: (@global-whitespace-large * 2) @global-whitespace-large;
  }
}

.signup__registration__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media (max-width: @global-screen-small-max) {
    margin-bottom: @global-whitespace-large;
  }

  .box__title {
    margin-bottom: @global-whitespace-small;

    @media (min-width: @global-screen-small-min) {
      margin-bottom: @global-whitespace-large;
    }
  }
}

//
// Secure App Footer
//


.signup__footer {
  background: lighten(@global-color-gray-darker, 4%);
  flex: none;
  text-align: center;
}

.signup__footer__address {
  color: @global-color-gray;
  font-size: @global-font-size-small;
  padding: @global-whitespace-xsmall 0;

  &:empty {
    display: none;
  }
}

.signup__footer__branding {
  background: @global-color-gray-darker;
  color: @global-color-gray;
  font-size: @global-font-size-small;
  padding: @global-whitespace-base;

  a {
    color: @global-color-gray;
  }
}

.signup__footer__container {
  padding: @global-whitespace-large;

  &:empty {
    display: none;
  }
}

.signup__footer__office {
  color: @global-color-white;
  font-weight: @global-font-weight-bold;
  padding: @global-whitespace-xsmall 0;
}

.signup__footer__phone {
  padding: @global-whitespace-small 0;

  &:empty {
    display: none;
  }
}


//
// Secure App Header
//


.signup__header {
  background: @global-color-primary;
  flex: none;
}

.signup__header__branding,
.signup__header__office {
  text-align: center;
}

.signup__header__branding {
  padding: @global-whitespace-base;
  position: relative;
}

.signup__header__branding__logo {
  display: inline-block;
  height: 3.2rem;
  max-width: 100%;

  @media (min-width: @global-screen-small-min) {
    height: 4rem;
  }
}

.signup__header__branding__logout {
  position: absolute;
  right: @global-whitespace-base;
  top: 50%;
  transform: translateY(-50%);
}

.signup__header__office {
  background: @global-color-info;
  color: @global-color-white;
  font-size: @global-font-size-small;
  font-weight: @global-font-weight-bold;
  padding: @global-whitespace-small @global-whitespace-base;

  @media (min-width: @global-screen-small-min) {
    font-size: @global-font-size-base;
  }
}
