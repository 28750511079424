//
// Component
//


@svg-load lightbox-download url(../../../node_modules/rhinostyle/src/svg/download.svg) {
  .icon-stroke {
    stroke: @global-color-white;
    stroke-width: 1.5;
  }
}

.lightbox__download {
  background-color: transparent;
  background-image: svg-inline(lightbox-download);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 70%;
  border: 0;
  cursor: pointer;
  height: 35px;
  opacity: 0.7;
  vertical-align: middle;
  width: 40px;

  &:hover {
    opacity: 1;
  }
}
