.download-app-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.download-app-modal__cancel-button {
  display: flex;
  justify-content: center;
  margin-top: @global-whitespace-base;
}

.download-app-modal__body-text {
  text-align: center;
  margin-bottom: @global-whitespace-large;
}

.download-app-modal__body-link {
  text-align: center;
}
