.analytics {
  .chartjs-size-monitor-shrink {
    position: static !important;
  }

  .app-page__header {
    margin-left: initial;
    margin-right: initial;
  }

  .convo__tabs {
    .button {
      width: 50px;
      padding: 0;
      color: @global-color-gray;

      &.active {
        border-bottom: 2px solid @global-color-primary;
        font-weight: bold;
        color: @global-color-primary;
      }

      &:hover {
        color: @global-color-primary;
      }
    }
  }

  .convo__toggle {
    margin: @global-whitespace-large 0;
    min-height: 70px;

    @media (max-width: @global-screen-medium-max) {
      text-align: center;
      min-height: unset;
      margin: @global-whitespace-small 0;
    }

    @media (max-width: @global-screen-small-max) {
      text-align: left;
    }

    .button--icon {
      width: @base-root-font-size * 2;
    }
  }

  .convo__bottom__pagination {
    margin-top: 0 !important;

    @media (max-width: @global-screen-medium-max) {
      margin-top: 0 !important;
    }

    & > div {
      margin-top: 0 !important;
    }

    .button--icon {
      width: @base-root-font-size * 2;
    }
  }

  .convo__empty {
    text-align: center;
    color: @global-color-gray-dark;
    font-weight: @global-font-weight-bold;

    p {
      font-weight: @global-font-weight-normal;
      margin-top: @global-whitespace-medium / 2;
      font-size: @global-font-size-small;
    }
  }

  .rt-table {
    button {
      height: 2rem;

      @media (max-width: @global-screen-medium-max) {
        height: auto;
      }
    }
  }

  .convo__header--fix-width {
    @media (max-width: @global-screen-medium-max) {
      max-width: 100%;
    }
  }

  .convo__header-left,
  .convo__header-toggle {
    @media (max-width: @global-screen-small-max) {
      padding-left: 0;
    }
  }

  .convo__pagination__buttons {
    @media (max-width: @global-screen-small-max) {
      margin-left: 3px !important;
    }
  }

  &__filter-label {
    font-size: @global-font-size-small;
    color: @global-color-gray;
    line-height: @global-whitespace-xlarge;
    height: @global-whitespace-xlarge;
  }

  .chart {
    max-width: 703px;
  }

  .rhinoform__action-button {
    height: 100%;

    button {
      height: 100%;
    }
  }

  &__header {
    font-size: @global-font-size-medium;
    font-weight: bold;
  }

  .analytics__open-conversation {
    .convo__toggle {
      margin: 0;
    }

    .rt-table {
      button {
        height: 100%;

        @media (max-width: @global-screen-medium-max) {
          height: 100%;
        }
      }
    }
  }
}

.analytics__charts-container {
  min-height: 420px;

  & > div {
    @media (max-width: @global-screen-small-max) {
      padding-left: 0;
    }
  }
}

.analytics__date-range {
  text-align: center;

  .dropdown__toggle {
    background-color: @global-color-gray-lightest;
    box-shadow: none;
    font-size: @global-font-size-small;
    height: @global-whitespace-large;
  }

  .dropdown__menu__scroll {
    overflow-y: unset !important;
  }

  .date-range__label {
    font-weight: @global-font-weight-bold;
    padding-bottom: @global-whitespace-small;
  }

  .dropdown__menu__divider {
    width: 90%;
    margin: 0 auto;
    margin-top: @global-whitespace-small;
    margin-bottom: @global-whitespace-small;
  }

  .dropdown__menu {
    font-size: @global-font-size-small;
    width: 300px;
  }

  .nav-tabs__item {
    width: auto;
    line-height: 30px;
    margin: 0 10px;
    padding: 0 5px;
  }

  &.nav-tabs__wrapper {
    margin-top: @global-whitespace-base;
  }

  &--campaigns {
    .daterange__dropdown {
      text-align: left;
    }
  }
}

.analytics__title {
  text-align: center;
  text-transform: uppercase;
  padding-top: @global-whitespace-medium / 2;
  padding-bottom: @global-whitespace-small;

  &--campaigns {
    text-align: left;
    text-transform: none;
    font-weight: @global-font-weight-bold;
  }
}

.analytics__wrapper {
  background: @global-color-white;
  padding: @global-whitespace-medium;
  box-shadow: @app-shadow-heavy;
  margin: @global-whitespace-medium / 2 0;
  min-height: 500px;
  position: relative;

  &--filter {
    padding-left: 0;
    padding-right: 0;
    box-shadow: none;
    margin: 0;
    border: 1px solid @global-color-gray-light;
  }

  .rt-table {
    .rt-td {
      padding-top: @global-whitespace-medium;
      padding-bottom: @global-whitespace-medium;
    }

    .analytics__date-row {
      white-space: pre;
    }

    @media (max-width: @global-screen-small-max) {
      .rt-th {
        height: 6rem;
      }

      .rt-td {
        padding-top: @global-whitespace-small;
        padding-bottom: @global-whitespace-small;
        height: 8rem;
      }
    }
  }
}

.analytic-filter__header {
  font-weight: @global-font-weight-bold;
  color: @global-color-gray;
  text-transform: uppercase;
  font-size: @global-font-size-small;
}

.analytics__container {
  max-width: 100%;
  padding: @global-font-size-base;
}

.analytic-filter__button {
  padding: 0;
  margin: 0 @global-whitespace-xsmall;
  color: @global-color-black;

  span {
    &::before {
      content: "";
      display: inline-block;
      width: 40px;
      height: 15px;
      margin-right: 5px;
      background-color: tint(@global-color-primary, 70%);
      border: 1px solid @global-color-primary;
      border-radius: @global-whitespace-xsmall / 2;
    }
  }

  &--inactive {
    text-decoration: line-through;
    color: @global-color-gray-light !important;

    span {
      &::before {
        background-color: @global-color-gray-light;
        border: 1px solid @global-color-gray-light;
      }
    }
  }
}

.doughnut__chart-wrapper {
  position: relative;
}

.doughnut__chart-title {
  position: absolute;
  top: 9rem;
  left: 3.6rem;
  font-weight: @global-font-weight-normal;

  @media (max-width: @global-screen-small-max) {
    top: 10rem;
    left: 2rem;
  }
}

.convo__header--left {
  @media (min-width: @global-screen-medium-min) {
    border-right: 1px solid @global-border-color;
    padding-right: 15px;
    max-width: 320px;
    width: 100%;
  }
}

.convo__toggle--closed-convo {
  @media (min-width: @global-screen-medium-min) {
    border-right: 1px solid @global-border-color;
    padding-right: 15px;
    max-width: 256px;
    width: 100%;
  }
}

.convo__conversation-info {
  font-size: @global-font-size-small;
  margin-top: @global-whitespace-small;
  display: block;
}

.convo__conversation-count {
  margin-bottom: 0;
  font-size: @global-font-size-large;

  @media (max-width: @global-screen-small-max) {
    font-size: @global-font-size-medium;
  }
}

.analytics__medicaid {
  .resource__intro__title__content {
    font-weight: @global-font-weight-normal;
  }

  .status-dropdown {
    min-width: 150px;

    select {
      overflow: hidden;
    }
  }
}

.analytics__medicaid-tab {
  min-height: calc(100vh + 80px);

  .convo__toggle {
    margin-top: 0;
  }

  .analytics__filter-label {
    height: @global-height;
    line-height: @global-height;
    padding: 0 0 1rem;
  }

  .status {
    &--circle {
      border-radius: 50%;
      height: 10px;
      width: 10px;
      display: inline-block;
    }

    &--expired {
      background-color: @global-color-accent;
      color: @global-color-accent;
    }

    &--completed {
      background-color: @global-color-success;
      color: @global-color-success;
    }

    &--sent {
      border: 1px solid @global-color-gray-darker;
    }

    &--text {
      background-color: transparent;
      border: none;
      padding-left: @global-whitespace-small;
    }
  }
}

.analytics__button-link {
  color: @global-color-gray-darker !important;
  text-decoration: underline;
}

.analytics__right-column {
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: @global-screen-small-max) {
    padding-right: 8px;
  }
}

.analytics__tooltip-box {
  padding: 5px 8px;

  .analytics__tooltip-message {
    margin-bottom: 0;
    padding-bottom: 5px;
    text-align: left;
  }
}

.analytics__tooltip-attachment {
  color: @global-color-gray;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.analytics__attachment-icon {
  margin-right: 8px;
}

.analytics__attachment-name {
  font-size: @app-font-size-micro;
  position: relative;
}

.analytics__message-icon {
  color: @global-color-primary;
}

.blastManager__attachment-icon {
  margin-left: 8px;
}

.open-conversation__message {
  border-radius: @global-whitespace-small;
  max-width: 30rem;

  &--outbound {
    padding: @global-whitespace-xsmall;
    border: 1.5px solid @global-color-primary;
  }

  &.msg--default {
    padding: @global-whitespace-xsmall;
  }
}

.open-conversation__table {
  padding-left: 1rem;
  padding-right: 1rem;
}
