.contact-list-form__wrapper {
  min-height: calc(100vh - 160px);

  @media (max-width: @global-screen-small-max) {
    padding: @global-font-size-base;
  }

  .contact--list-search {
    .add__member__btn {
      border-radius: 50%;
    }

    .dropdown__menu__scroll {
      @media (max-width: @global-screen-small-max) {
        max-height: 180px;
      }
    }

    &__filter {
      & + .dropdown__menu {
        position: absolute;
        top: 55px;
      }
    }
  }

  .contact-list-form__button-wrapper {
    padding-top: @global-whitespace-base;
  }

  .contact-list-form__result-wrapper {
    border-radius: @global-border-radius;

    @media (max-width: @global-screen-small-max) {
      max-height: 200px !important;
    }
  }

  .contact-list-form-name__wrapper {
    display: flex;
    flex-direction: row;
    flex: 1;
    flex-wrap: wrap;
    justify-content: center;

    .contact-list-form-name__input {
      flex-grow: 1;
      max-width: 50rem;
    }
  }

  .resource {
    padding-right: @global-height-small !important;
  }

  .resource__wrapper {
    @media (max-width: @global-screen-small-max) {
      padding-top: @global-font-size-xsmall;
      padding-bottom: @global-font-size-xsmall;
    }
  }
}

.contact-list-search__wrapper {
  display: flex;

  .search__input {
    flex: 1;
    margin-bottom: 0;
  }

  .dropdown__toggle {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 90px;
    padding: 0;

    @media (max-width: @global-screen-small-max) {
      width: @global-whitespace-xlarge;
    }

    .dropdown__toggle__text {
      @media (max-width: @global-screen-small-max) {
        display: none;
      }
    }
  }

  .form__control {
    background: @global-background;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.contact-list-search__pill-input__input--large {
  margin-top: @global-whitespace-xsmall;
  margin-bottom: -(@global-whitespace-xsmall / 2) !important;
}

.search__modal__pills-input {
  @media (max-width: @global-screen-small-max) {
    max-height: 80px !important;
  }
}

.search__modal__lower-body-content-left {
  @media (max-width: @global-screen-small-max) {
    width: auto;
    float: left;
  }
}

.app-page--contact-list {
  display: block;
  overflow-y: auto;

  .list-panel {
    padding-right: @global-font-size-base;

    @media (max-width: @global-screen-small-max) {
      padding-right: 0;
    }
  }
}
