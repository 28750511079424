.item-status {
  display: flex;
  align-items: center;
}

.item-status__left {
  margin-right: @global-whitespace-base;
}

.item-status__right {
  margin-left: auto;
}

.item-status__delete-icon {
  color: @global-font-color-muted;

  &:hover,
  &:focus {
    color: @global-color-danger;
  }
}

.item-status__confirm__text {
  text-align: center;
  font-size: @global-font-size-small;
  margin-bottom: @global-whitespace-base;
}
