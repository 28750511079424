@rhinocall-background-color: #404040;
@rhinocall-border-radius: 0.8rem;
@rhinocall-modal-width: 25rem;
@rhinocall-modal-height: 35rem;

.rhinocall__modal {
  background-color: @rhinocall-background-color;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: @rhinocall-modal-width;
  border-radius: @rhinocall-border-radius;

  &--active-call {
    min-height: @rhinocall-modal-height;
  }

  .rhinocall__form {
    width: 100%;
    padding: @global-whitespace-base;
  }

  .rhinocall__input {
    width: 100%;

    label {
      color: @global-color-white;
      font-weight: @global-font-weight-normal;
    }

    input {
      padding: @global-whitespace-small;
    }

    .rhinoselect__single-option {
      height: auto;
    }

    option {
      width: 100%;
      overflow: hidden;
      word-wrap: normal !important;
      white-space: normal;
    }
  }

  .rhinocall__title__preconfig {
    text-align: center;
    color: @global-color-white;
    margin: @global-whitespace-base;
  }

  .rhinocall__title__complete {
    text-align: center;
    color: @global-color-white;
    margin: @global-whitespace-large 0;
  }

  .rhinocall__footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: @global-whitespace-large;
  }

  // Timer
  .rhinocall__timer-container {
    display: flex;
    justify-content: center;
    font-size: @global-font-size-small;
    height: 4.5rem;

    .rhinocall__timer {
      padding: @global-whitespace-xsmall;
      background-color: @rhinocall-background-color;
      border-bottom-left-radius: @global-border-radius;
      border-bottom-right-radius: @global-border-radius;
    }
  }

  .rhinocall__contact-info_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .rhinocall__avatar {
    width: 5rem;
    height: 5rem;
  }

  .rhinocall__contact-phone {
    font-weight: @global-font-weight-bold;
    font-size: @global-font-size-medium;
    display: flex;
    justify-content: center;
    color: @global-color-white;
  }

  .rhinocall__contact-name {
    font-size: @global-font-size-base;
    display: flex;
    justify-content: center;
    color: @global-color-white;
  }

  .rhinocall__call-status {
    width: 100%;
    height: 4rem;
    color: @rhinocall-background-color;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: @global-font-weight-bold;
    font-size: @global-font-size-medium;
    margin-top: @global-whitespace-base;

    .rhinocall__call-status-icon {
      margin-right: 1rem;
    }
  }

  .rhinocall__action-bar {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: @global-whitespace-base;
  }

  .rhinocall__action-bar__button,
  .rhinocall__action-bar__hangup-button,
  .rhinocall__dialpad-button {
    transition: none;
    color: @global-font-color;
    border-radius: 50%;
    margin: @global-whitespace-small @global-whitespace-base;
    background-color: @global-color-white;

    &:focus {
      color: @global-font-color;
      background-color: @global-color-white;
    }

    @media (hover: hover) {
      &:hover {
        transition: 0.2s ease-in-out;
        background-color: @global-color-gray-light;
      }
    }
  }

  .rhinocall__action-bar__hangup-button {
    background-color: @global-color-danger;
    color: @global-color-white;

    @media (hover: hover) {
      &:hover {
        background-color: @global-color-danger;
        color: @global-color-white;
      }
    }
  }

  .rhinocall__action-bar__label {
    font-size: @global-font-size-xsmall;
    color: @global-color-white;
    text-align: center;
  }

  .rhinocall__dialpad-wrapper {
    padding: 0 @global-whitespace-large;
  }

  .rhinocall__dialpad-buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 1.6rem;

    .rhinocall__dialpad-button {
      margin: @global-whitespace-xsmall @global-whitespace-medium;
    }

    .rhinocall__dialpad-button-astericks {
      font-size: 3rem;
      padding-top: 1rem;
    }
  }

  .rhinocall__dialpad-value {
    font-size: @global-font-size-large;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .rhinocall__dialpad-value-astericks {
      bottom: -1rem;
      position: relative;
      font-size: 4rem;
    }
  }

  .rhinocall__dialpad-hide {
    color: @global-color-white;
    margin-bottom: @global-whitespace-base;
    cursor: pointer;
  }

  .rhinocall__audio-select {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: @global-color-gray;
  }

  .rhinocall__close {
    position: absolute;
    right: 0;
    top: 0;
    padding: @global-whitespace-base;
  }

  @media (orientation: landscape) and (max-height: @global-screen-xsmall-max) {
    padding: 0 @global-whitespace-small;
    max-height: 100vh;

    .rhinocall__preconfig_modal-title {
      margin: @global-whitespace-small;
    }

    .rhinocall__input {
      label {
        margin-top: 0;
      }
    }

    .rhinocall__preconfig_modal-footer {
      margin-top: @global-whitespace-base;
      margin-bottom: @global-whitespace-base;
    }
  }

  .rhinocall__telephone {
    height: 0;
    opacity: 0;
  }
}
