//
// Component
//


// 1. Matches width/height from index.html loader
.app-page-loader {
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);

  // 1
  .loader-circle {
    width: 64px;
    height: 64px;
  }
}
