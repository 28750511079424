.global-search {
  .add__member__btn {
    border-radius: 50%;
  }

  .dropdown__menu__scroll {
    @media (max-width: @global-screen-small-max) {
      max-height: 180px;
    }
  }

  &__filter {
    & + .dropdown__menu {
      position: absolute;
      top: 55px;
    }
  }
}

.global-search__wrapper {
  display: flex;
  margin-bottom: @global-whitespace-base;

  .search__input {
    flex: 1;
    margin-bottom: 0;
  }

  // 1. Fudge width to keep at consistent size as labels change
  .dropdown__toggle {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 90px; // 1
    padding: 0;
  }

  .form__control {
    background: @input-bg;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.search__count {
  font-size: @global-font-size-xsmall;
  color: @global-font-color-muted;
}

// @TODO -- Consider moving this to Rhinostyle to accommodate large field containers with the pill input without text alignment issues.
.global-search__pill-input__input--large {
  margin-top: @global-whitespace-xsmall;
  margin-bottom: -(@global-whitespace-xsmall / 2) !important; // Important required to overwrite .modal__body :last-child style.
}

.global-search-header__desktop {
  display: flex;
  width: 45rem;
  position: relative;
  margin-right: @global-whitespace-small;

  @media (max-width: @global-screen-medium-max) {
    width: 35rem;
  }

  .search__input {
    flex: 1;
    margin-bottom: 0;

    .form__addon__item {
      background: @input-bg;
    }

    .form__addon__item--right {
      padding-right: 0 !important;
    }

    .button--large {
      height: inherit;
    }

    .dropdown__toggle {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border: none;
      width: 90px;
      padding: 0;
      background: @input-bg;
      color: @global-font-color-muted;

      .dropdown__toggle__text {
        font-size: @global-font-size-small;
      }
    }
  }

  .form__control {
    background: @input-bg;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .search-result-wrapper {
    position: absolute;
    width: 100%;
    top: 100%;
    margin-top: @global-whitespace-xsmall;
    left: 0;
    border-radius: @global-border-radius;
    background: @global-background;

    .resource-group__scroll,
    .resource-group__no-results {
      border-top-right-radius: @global-border-radius;
      border-top-left-radius: @global-border-radius;
      // important needed to override default max-height
      max-height: 35rem !important;
    }

    .resource-group__scroll__no-permission {
      border-radius: @global-border-radius;
    }

    .resource-group__scroll > div:first-child {
      max-height: 35rem !important;
    }

    .search__no-results {
      background: @global-background;
      color: @global-color-primary;
    }

    .search__create-contact {
      border: 1px solid @global-border-color;
      border-bottom-left-radius: @global-border-radius;
      border-bottom-right-radius: @global-border-radius;
      border-top: none;

      &__no-results {
        border-radius: @global-border-radius;
        border: 1px solid @global-border-color;
      }
    }
  }
}

.global-search-header__mobile__icon {
  display: none;
}

@media (max-width: @global-screen-small-max) {
  .global-search-header__desktop {
    display: none;
  }

  .global-search-header__mobile__icon {
    display: inherit;
  }

  .global-search-header__mobile {
    .search__input {
      margin-bottom: 0;

      .input__wrapper {
        padding-left: @global-whitespace-small;
      }

      .form__addon {
        height: 6rem;

        .form__addon__item,
        .form__control,
        .dropdown__toggle {
          border: none;
          background: @global-color-white;
        }

        .icon-stroke {
          stroke-width: @icon-stroke-width-thin;
        }

        .dropdown__toggle__text {
          display: none !important;
        }
      }
    }

    .resource-group__scroll {
      max-height: 25rem !important;

      & > div:first-child {
        max-height: 25rem !important;
      }
    }
  }
}

.global-search__modal__wrapper {
  .modal {
    opacity: 1;
    display: block;
  }
}

@media (max-width: @global-screen-xsmall-max) {
  .global-search-header__mobile {
    .resource-group__scroll {
      max-height: 20rem !important;

      & > div:first-child {
        max-height: 20rem !important;
      }
    }
  }
}
