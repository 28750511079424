.integration-analytics__timestamp {
  padding: @global-whitespace-base;
  background: @global-color-panel;
  margin-top: @global-whitespace-base;
  margin-bottom: @global-whitespace-large;
  text-align: center;
}

.integration-analytics__timestamp-text {
  display: flex;
  justify-content: center;
}

.integration-analytics__timestamp-text-item {
  margin-left: @global-whitespace-base;
  margin-right: @global-whitespace-base;
}

.integration-analytics__datepicker {
  margin-top: @global-whitespace-base;
  margin-bottom: @global-whitespace-base;
  margin-right: @global-whitespace-base;
  margin-left: @global-whitespace-base;
  display: flex;
  justify-content: center;
}

.integration-analytics__date-time-picker {
  display: flex;
  justify-content: center;
}

.integration-analytics__table {
  margin-top: 0;
  margin-bottom: @global-whitespace-base;
  padding-top: @global-whitespace-small;
}

.integration-analytics__table__table-name {
  flex: 1;
  font-size: @global-font-size-medium;
  font-weight: @global-font-weight-bold;
  padding-bottom: @global-whitespace-small;
  padding-top: @global-whitespace-medium;
}

.integration-analytics__table__table-row {
  vertical-align: middle;
}

.integration-analytics__table__data-column {
  text-align: center;
}

.integration-analytics__table__details-column {
  width: 100%;
  min-width: 24rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

// important tags are dangerous, but this class should only be used IntegrationAnalytics.jsx
.integration-analytics__table__data-cell {
  padding: 0 !important;
}

.integration-analytics__table__appointments-table-parent-div {
  padding-bottom: 30px;
}

.integration-analytics__table__csv-table-parent-div {
  padding-top: 30px;
}

.integration-analytics__api-contact-detail-box {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.integration-analytics__api-contact-detail-box-content {
  display: flex;
  flex-direction: column;
  text-align: right;
  font-size: @global-font-size-small;
}

.integration-analytics__api-contact-detail-content__item {
  padding: 5px;
}

.integration-analytics__api-contact-detail-content__header {
  padding-top: 5px;
  padding-left: 5px;
  font-size: 14px;
}

.integration-analytics__message__empty-state {
  height: 200px;
  text-align: center;
  padding-top: 70px;
  color: #404040;
  font-weight: @global-font-weight-bold;
  width: 90%;
  margin: 0 auto;
  font-size: 18px;

  p {
    font-weight: @global-font-weight-normal;
    margin-top: @global-whitespace-medium / 2;
    font-size: @global-font-size-small;
  }
}
