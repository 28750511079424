//
// Variables
//

@billing-estimate-border-color: darken(@global-color-primary-light, 10%);


//
// Component
//


.billing__usage-meter {
  display: inline-flex;
  vertical-align: bottom;
  width: 100px;
  height: 100px;

  circle {
    stroke: @billing-estimate-border-color;
    stroke-width: 2px;
    stroke-dasharray: 0;
    fill: none;
  }

  path {
    stroke-width: 5px;
    stroke: transparent;
    fill: none;
    transition: stroke-dashoffset 1s @global-trans-timing-cubic;
    transform-origin: center center;
    transform: rotate(-90deg) scaleX(-1);
  }

  text {
    font-weight: @global-font-weight-bold;
    fill: @global-color-primary;
  }

  &.has-warning {
    path {
      stroke: @global-color-accent;
    }

    text {
      fill: @global-color-accent;
    }
  }

  &.has-danger {
    path {
      stroke: @global-color-danger;
    }

    text {
      fill: @global-color-danger;
    }
  }

  &.has-success {
    path {
      stroke: @global-color-success;
    }

    text {
      fill: @global-color-success;
    }
  }
}

.billing__estimate__detail-row {
  td {
    border-color: transparent;

    &:first-child {
      padding-left: @global-whitespace-large;
    }
  }
}
