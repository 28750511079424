//
// Component
//

.consent-status__icon,
.convo__event__icon {
  &.is-granted {
    color: @global-color-secondary;
  }

  &.is-denied {
    color: @global-color-danger;
  }

  &.is-unknown {
    color: @global-color-warning;
  }

  &.is-marketing {
    .icon-stroke {
      stroke-width: @icon-stroke-width-thin;
    }
  }

  &.is-primary {
    color: @global-color-secondary;
    padding-left: 4px;
  }

  &.is-secondary {
    color: @global-color-warning;
    padding-left: 4px;
  }
}

.hipaa-status--summary {
  margin-top: @global-whitespace-base;
  padding-bottom: @global-whitespace-base;
  border-bottom: 1px solid @global-border-color;

  &:first-child {
    margin-top: @global-whitespace-small;
  }

  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
}

.hipaa-status__options {
  padding-top: @global-whitespace-base;
}

.hipaa-status__text {
  margin: 0 @global-whitespace-small 0 0;

  &:only-child {
    margin-right: 0;
  }
}

.hipaa-status__summary-header {
  background: @global-color-panel;
  padding: @global-whitespace-base;
  position: relative;

  // 1. Increase right padding to offset arrow
  &.is-clickable {
    cursor: pointer;
    // 1
    padding-right: (@global-whitespace-base + @global-font-size-xsmall + @global-whitespace-xsmall);

    @svg-load caret-down url(../../../node_modules/rhinostyle/src/svg/caret-down.svg) {
      .icon-stroke {
        stroke: @global-font-color;
      }
    }

    &::after {
      content: '';
      position: absolute;
      background-color: transparent;
      background-image: svg-inline(caret-down);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      width: @global-font-size-xsmall;
      height: @global-font-size-xsmall;
      top: 50%;
      right: @global-whitespace-base;
      transform: translateY(-50%);
    }
  }

  @svg-load caret-up url(../../../node_modules/rhinostyle/src/svg/caret-up.svg) {
    .icon-stroke {
      stroke: @global-font-color;
    }
  }

  &.is-open {
    &::after {
      background-image: svg-inline(caret-up);
      stroke: @global-font-color;
    }
  }
}

.hipaa-status__confirm {
  margin-top: @global-whitespace-base;
}

.hipaa-status__show-more {
  display: flex;
  justify-content: center;
  margin-top: @global-whitespace-base;
  border-top: 1px solid @global-border-color;
  padding-top: @global-whitespace-base;

  .button--reset {
    text-transform: uppercase;
    color: @global-color-primary;
    font-size: @global-font-size-small;
  }
}

.sliding-radio__container {
  padding: @global-whitespace-base;
  border-bottom: 1px solid @global-border-color;
}

.sliding-radio__content {
  margin: @global-whitespace-base;
}

.modal__dialog__warning {
  border: solid  @global-color-danger;
}
