.profile-panel__nav-tabs-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -@global-whitespace-base;
  padding-top: @global-whitespace-medium / 2;
  padding-bottom: @global-whitespace-small;
  text-transform: capitalize;

  .nav-tabs__item {
    width: auto;
    line-height: 30px;
    margin: 0 10px;
    padding: 0 5px;
    margin-bottom: 0;

    &--divider {
      display: inline;
      border-left: 1.5px solid @global-color-gray;
      font-size: @global-font-size-xsmall;
    }

    .nav-tabs__item__link {
      font-size: @global-font-size-base;
    }
  }


  ul {
    margin-bottom: 0;
  }
}

.profile-panel__appointment-status {
  line-height: @global-line-height-small;
  margin-bottom: @global-whitespace-small;
}
