//
// Variables
//


@app-login-opacity: 0.75;
@app-login-input-background: lighten(@global-color-primary, 3.5%);
@app-login-label-active-offset: translate(0, ((-@global-whitespace-base * 2) + -@global-whitespace-small));


//
// Component
//


// 1. Override `data-vh-calculated` inline style
.app-login {
  background: @global-color-primary;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: @global-whitespace-base;
  position: relative;
  width: 100%;
  height: auto !important; // 1
}


//
// App Login Container
//


.app-legacy-login__container {
  max-width: 38rem;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  text-align: center;
}


//
// App Login Floating Label
//


.app-login__floating-label {
  position: relative;
}

// 1. Remove input transition to avoid autofill `background-color` flash
.app-login__floating-label__input {
  background: @app-login-input-background;
  border: 0;
  color: @global-color-white;
  overflow: hidden;
  transition: none; // 1

  &::selection {
    background: @global-color-white;
  }

  // 1. Takes care of autofilled form data
  &:-webkit-autofill {
    -webkit-text-fill-color: @global-color-white;
    box-shadow: inset 0 0 0 60px @app-login-input-background;

    ~ .app-login__floating-label__label { // 1
      font-size: @global-font-size-small;
      opacity: @app-login-opacity;
      transform: @app-login-label-active-offset;
    }
  }

  // 1. Takes care of autofilled form data
  // Cannot combine with `&:-webkit-autofill` from above because of FF bug
  &.has-value {
    ~ .app-login__floating-label__label { // 1
      font-size: @global-font-size-small;
      opacity: @app-login-opacity;
      transform: @app-login-label-active-offset;
    }
  }
}

.app-login__floating-label__label {
  color: @global-color-white;
  display: block;
  font-weight: @global-font-weight-normal;
  line-height: 1;
  margin: 0;
  max-width: 100%;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  top: 50%;
  transform: translate(@input-padding-base, -50%);
  transition: transform 0.25s @global-trans-timing-cubic;
  user-select: none;
  white-space: nowrap;
}


//
// Rhinobox inverse
//


.rhinobox--contrast {
  label {
    color: @global-color-white;

    &::before {
      background-color: transparent;
      border-color: @global-color-white;
    }
  }

  input:checked {
    + label::before {
      background-color: transparent;
      border-color: @global-color-white;
    }
  }
}


//
// App Login Headline
//


.app-login__headline {
  color: @global-color-white;
}


//
// App Login Logo
//


.app-login__logo {
  margin: 0 auto @global-whitespace-xlarge;
  max-width: 28rem;
  width: 100%;

  img {
    height: 150px;
    width: 100%;
  }
}


//
// App Login Secondary Button
//


.app-login__secondary-button {
  color: @global-color-white;
  display: inline-block;
  opacity: @app-login-opacity;

  &:hover,
  &:focus {
    opacity: 1;
    color: @global-color-white;
  }
}


//
// App Login Text
//


.app-login__text {
  color: @global-color-white;
  opacity: @app-login-opacity;
}


//
// App Login Wrapper
//


.app-login-wrapper {
  display: flex;
}

.login-redirect__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex: 1;
  color: white;
}

.login-redirect__header__title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: @global-whitespace-base;
}

.axium-login__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  text-align: center;
  color: @global-color-white;
  font-size: 1.8rem;
  margin-top: @global-whitespace-large*2;
}

.axium-login__header {
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: @global-whitespace-base;
}
