.member-section__header {
  padding: 20px;
  background-color: @global-background;
  border: 1px solid @global-border-color;
  border-bottom: none;
}

.member-list__nocontacts_wrapper {
  padding-top: 50px;
}

.member-section__label {
  font-weight: @global-font-weight-bold;
}

.create-member_button-description {
  font-size: @global-font-size-xsmall;
  margin: 0;
  padding-top: 2px;
}
