// Note: Used for HIPAA and Minor icons next to user's name

//
// Component
//


.user-badges {
  position: relative;
  top: -0.1rem;
  white-space: nowrap;

  &:empty {
    display: none;
  }

  .icon {
    margin-left: @global-whitespace-xsmall;
  }

  .resource__intro__title__meta & {
    &:only-child .icon:first-child {
      margin-left: 0;
    }
  }
}
