.draggable-container {
  position: absolute;
  width: 100%;
  height: 100%;

  .draggable-child {
    z-index: @modal-zindex;
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }

  &.draggable-container__fullscreen {
    width: 100%;
    top: 0;
    left: 0;

    .draggable-child {
      display: block;
      transform: translate(0);
    }
  }

  &.draggable-container__dock-bottom-right {
    top: 100%;
    left: 100%;

    .draggable-child {
      transform: translate(-100%, 0);
      position: absolute;
      bottom: 0;
    }
  }

  &.draggable-container__dock-bottom-left {
    top: 100%;
    left: -100%;

    .draggable-child {
      transform: translate(150%, 0);
      bottom: 0;
      position: absolute;
    }
  }

  &.draggable-container__dock-sidebar {
    position: absolute;
    width: 100%;
    height: 100%;

    .draggable-child {
      position: absolute;
      bottom: 0;
      left: 0;

      @media (min-width: @global-screen-large-min) {
        left: @app-sidebar-width;
      }
    }
  }
}

.draggable-container__disabled {
  position: absolute;
  top: 0;
  z-index: @modal-zindex;
  height: 100%;
}

.is-dragging {
  ::selection {
    background: transparent !important;
    color: inherit;
  }

  .draggable-child {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
}
