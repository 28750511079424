.u-whitespace-base {
  padding-bottom: @global-whitespace-base;
}

.u-flex-1 {
  flex: 1;
}

.u-link {
  color: @global-color-primary;
  cursor: pointer;
  text-decoration: underline;
}

.u-muted-outline {
  .u-text-muted;

  border-color: @global-color-gray;
}

.u-opacity-none {
  opacity: 0;
}

.u-position-relative {
  position: relative;
}

.u-select__loader-circle {
  position: absolute;
  z-index: 1;
  left: 50%;
  bottom: 6px;
  transform: translateX(-50%);
  .u-text-primary;
}

.u-absolute-center {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.u-text-rtl {
  direction: rtl;
}

.u-font-weight-thin {
  font-weight: 200;
}

.u-text-xsmall {
  font-size: @global-font-size-xsmall;
}

.u-wrap-text {
  white-space: normal !important;
  word-wrap: break-word !important;
}

.u-p-xsmall {
  padding: @global-whitespace-xsmall;
}

.u-text-gray {
  color: @global-color-gray;
}

.u-border-radius {
  border-radius: 50%;
}

.u-flex-grow {
  flex-grow: 1;
}

.u-visibility--hidden {
  display: none;
}

.u-visibility--visible {
  display: block;
}
