
.icon-adjacent-beta-label {
  font-size: 1rem;
  padding-right: 2.6rem;
  position: absolute;
  color: @global-color-success;
  top: 1rem;
}

.title-adjacent-beta-label {
  font-size: 1rem;
  vertical-align: top;
  padding-left: 0.3rem;
  display: inline-block;
  color: @global-color-success;
  font-weight: 100;
}
