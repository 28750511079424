.bulk-actions {
  .app-page__header {
    padding: 6px 0 6px;

    .header__title__center {
      margin-left: auto;
      margin-right: auto;
    }

    .bulk-action__header__title {
      margin: 0 5rem;
    }

    &.dropdown-chekbox {
      position: absolute;
    }

    &.no-bulk__header {
      justify-content: center;
      margin: 6px 0;
    }
  }

  .rhinobox__input {
    background-color: transparent;
  }
}

.bulk-actions__assign__modal {
  .app-panels--hide-icons-desktop {
    .app-page__header__action__close {
      display: block;
    }
  }

  .summary-panel__body {
    border: none;

    > div:first-child {
      position: static !important;
    }

    > div:nth-child(2) {
      display: none;
    }

    > div:last-child {
      display: none;
    }
  }
}

@media only screen and (max-width: @global-screen-small-min) {
  .bulk-actions {
    .app-page__header {
      margin: 0 0.4rem 0 0;
    }

    .app-page__header__action {
      right: 6px;
    }
  }
}

.bulk-select-header {
  justify-content: space-between;
  padding: 6px 0 6px 3px;
  background-color: #eff7fb;
}

.bulk-outer-header {
  min-height: 5.6rem;

  .bulk-select-header {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    padding: @global-whitespace-small @global-whitespace-large;
    display: flex;
    justify-content: space-between;

    @media (max-width: @global-screen-nano-min) {
      padding: @global-whitespace-small;
    }
  }

  .search__max-contact {
    font-size: @global-font-size-base;
    padding: @global-whitespace-small 0 0;
    color: @global-color-danger;

    @media (max-width: @global-screen-small-min) {
      position: absolute;
      top: 50px;
      left: 0;
      right: 0;
      padding: @global-whitespace-small;
      text-align: center;
    }
  }
}

.bulk-outer-header__max-contact {
  @media (max-width: @global-screen-small-min) {
    min-height: 75px;
  }
}

/* remove modal css  after demo */
.bulk-action-modal {
  .modal__body {
    padding: 30px 50px 50px;
  }

  span {
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: @global-font-size-medium;
    font-weight: @global-font-weight-bold;
    cursor: pointer;
  }
}

.bulk-header-pagination {
  position: absolute;
  right: 20px;
  top: 98px;
}

.bulk-max-limit {
  position: absolute;
  left: 85px;
  bottom: 4px;
  font-size: @global-font-size-xsmall;

  @media (max-width: @global-screen-nano-min) {
    left: 65px;
  }
}

.bulk-max-selected {
  .dropdown__toggle__text {
    color: @global-color-danger;
  }
}

.bulk-select {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: @global-whitespace-small @global-whitespace-base;
  background: @global-color-white;
  min-height: 5.6rem;
  margin-top: @global-whitespace-small;

  &--contacts {
    padding: @global-whitespace-small @global-whitespace-base;
  }

  &--forms {
    padding: @global-whitespace-small @global-whitespace-large + 0.2rem;

    @media (max-width: @global-screen-small-max) {
      padding: @global-whitespace-small @global-whitespace-medium * 2;
    }
  }


  &--selected {
    background: #eff7fb;
  }

  &__action__dropdown {
    font-size: @global-font-size-small;
  }

  &__action {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
  }

  &__checkbox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: @global-font-size-small;
    min-width: 15rem;

    .dropdown__toggle__text {
      padding-left: @global-whitespace-small;
    }
  }

  &__text {
    font-size: @global-font-size-small;
    line-height: @global-font-size-medium;
    margin: auto;

    &--info {
      font-size: @global-font-size-xsmall;
      line-height: @global-font-size-xsmall;
      margin-top: 2px;
    }

    &--wide {
      min-width: 17.5rem;
    }
  }

  &__clear {
    font-size: @global-font-size-small;
  }

  &__divider {
    border-left: 1px solid @global-color-gray;
    height: @global-font-size-medium;
    margin: auto;
    margin-left: @global-whitespace-small;
    width: 0;
  }

  @media (max-width: 570px) {
    &__text {
      padding-right: @global-whitespace-small;
      font-size: @global-font-size-xsmall;
    }

    .appointments-button {
      font-size: @global-font-size-small;
    }

    &__clear {
      font-size: @global-font-size-xsmall;
    }
  }


  @media (max-width: @global-screen-xsmall-max) {
    height: inherit;

    &__text,
    &__clear,
    .appointments-button {
      font-size: @global-font-size-xsmall;
      word-wrap: break-word;
      white-space: normal;
      line-height: @global-font-size-medium;
      text-align: center;
    }

    &__divider,
    &__clear {
      display: none;
    }
  }
}

.bulk-warning-text {
  color: @global-color-accent;
  font-size: @global-font-size-small;
  font-style: italic;
}

.bulk-warning-box {
  padding-top: 10px;
}
