.pagination__caret--disabled {
  color: @global-color-gray-light;
}

.pagination__wrapper {
  font-size: @global-font-size-small;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;

  @media (max-width: @global-screen-xsmall-max) {
    flex-direction: column;
    align-items: flex-start;
  }

  &--inbox {
    justify-content: space-between;
    flex: 1;
  }
}
