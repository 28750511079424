.library-page,
.template__form-listing {
  display: flex;

  @media (min-width: @global-screen-medium-min) {
    .list-panel__wrapper {
      flex-basis: calc(100% - @app-panel-summary-width + 6rem ); /* stylelint-disable-line */ // https://github.com/stylelint/stylelint/issues/4114
    }

    .summary-panel__wrapper {
      flex-basis: @app-panel-summary-width - 6rem;
    }

    .search-filter-wrapper {
      flex-basis: 50%;
    }
  }

  .library-scroll__container {
    position: absolute !important;
  }

  .list-panel__body__scroll {
    height: 55vh !important;
    margin-top: @global-whitespace-medium;
    border-top: none;

    @media (max-width: @global-screen-small-max) {
      height: 50vh !important;
    }

    & div {
      z-index: 3;
    }
  }

  .app-panels > * {
    overflow: unset;
  }
}

.library__title {
  display: flex;
  text-align: left;
  text-transform: capitalize;
  padding-top: @global-whitespace-medium / 2;
  padding-bottom: @global-whitespace-small;
  align-items: center;

  @media (max-width: @global-screen-small-max) {
    display: inline-block;
  }

  .nav-tabs__item {
    width: auto;
    line-height: 30px;
    margin: 0 10px;
    padding: 0 5px;
    margin-bottom: 0;
  }

  ul {
    margin-bottom: 0;
  }
}


.library__wrapper {
  background: @global-color-white;
  padding: @global-whitespace-medium;
  box-shadow: @app-shadow-heavy;
  min-height: 400px;
  position: relative;
  margin-bottom: @global-whitespace-base;

  .title-text {
    position: relative;
    bottom: 5px;
  }

  .title-draft-text {
    color: @global-color-accent;
    font-size: @global-font-size-small;
    margin: 0;
    position: absolute;
    bottom: 2px;
  }

  .form-row {
    border-left: 3px solid transparent;
  }

  .form-row:hover {
    border-left: 3px solid @global-color-primary;
  }

  .selected-form-template {
    font-weight: bold;
    border-left: 3px solid @global-color-primary;
  }

  @media (max-width: @global-screen-small-max) {
    .title-text {
      bottom: 2px;
    }

    .title-draft-text {
      bottom: 1px;
      font-size: @global-font-size-xsmall;
    }
  }

  .app-lazy-loader {
    bottom: 8%;
    z-index: 10;
  }

  .heart-icon {
    position: relative;
    right: 10px;

    &:hover {
      color: @global-color-danger;
    }
  }

  .heart-filled-icon {
    position: relative;
    right: 10px;
    color: @global-color-danger;
  }


  .rt-table {
    .rt-td {
      padding-top: @global-whitespace-medium;
      padding-bottom: @global-whitespace-medium;

      div {
        white-space: nowrap;
      }
    }
  }

  &--header {
    display: flex;
    padding: @global-whitespace-medium @global-whitespace-small;
    justify-content: space-between;
    align-items: center;

    @media (max-width: @global-screen-small-max) {
      flex-wrap: wrap;
      padding-left: 0;
      padding-right: 0;
    }

    * {
      margin-bottom: 0;
    }

    .search-filter-wrapper {
      @media (max-width: @global-screen-small-max) {
        min-width: 250px;
        margin-right: @global-whitespace-medium;
        flex: none;
        margin-top: @global-whitespace-large;
        width: 100%;
      }

      .dropdown {
        @media (max-width: @global-screen-small-max) {
          width: 40px;
        }
      }
    }

    & > button {
      @media (max-width: @global-screen-small-max) {
        max-width: 35px;
        max-height: 35px;
        flex: 1;
        margin-top: @global-whitespace-large;
      }
    }

    .form-heading {
      font-size: @global-font-size-medium;

      @media (max-width: @global-screen-small-max) {
        width: 100%;
      }
    }

    .search__input {
      .form__addon__item {
        border: none;
      }
    }

    .button {
      border-radius: 50%;
    }
  }

  @media (max-width: @global-screen-small-max) {
    &--templates {
      .rt-td {
        max-width: none;
        min-width: auto;
      }
    }
  }

  .add-btn {
    @media (max-width: @global-screen-small-max) {
      position: absolute;
      top: @global-whitespace-base;
      right: @global-whitespace-base;
    }
  }
}


.form-details__summary-panel {
  .summary-panel__wrapper {
    padding-right: @global-whitespace-large;
  }

  .summary-panel__intro__title {
    align-items: center;

    .signature-icon {
      margin-right: @global-whitespace-medium;
    }
  }

  .form-detail__heading {
    font-size: @global-font-size-small;
    font-weight: bold;
    color: @global-color-gray-dark;
  }

  .form-detail__info {
    padding: @global-whitespace-small @global-whitespace-medium;
    height: 35vh;
    word-break: break-all;
  }

  .form-detail__creater {
    color: @global-color-gray;
    font-size: @global-font-size-small;
  }
}

.form-list__noforms_wrapper {
  margin-top: @global-whitespace-medium;
}

.library__container {
  .app-page__header {
    @media (max-width: @global-screen-small-max) {
      min-height: @global-height + 2;
    }
  }
}

.form-modal {
  z-index: @modal-zindex + 2;

  .modal__header {
    padding-top: @global-whitespace-large;
    padding-bottom: 0;

    &__close {
      margin-top: 0;
      top: 2.4rem;
    }
  }

  .nav-tabs__item--header {
    font-size: @global-font-size-large;
  }

  &__upload {
    position: absolute;
    top: 2.4rem;
    left: 2.4rem;
  }

  &__template__upload {
    position: absolute;
    top: 2.4rem;
    left: 1rem;
  }

  .list-panel__body__scroll {
    min-height: 40rem !important;
    height: 45rem;
    margin-top: 1.2rem;
    border-top: none;

    @media (max-width: @global-screen-small-max) {
      height: 22vh !important;
    }
  }

  .nav-tabs__item {
    width: auto;
    line-height: 30px;
    margin: 0 10px;
    padding: 0 5px;
    margin-bottom: 0;
  }

  .rt-thead {
    z-index: 0;
  }

  .dropdown__menu__item {
    z-index: 4;
  }

  .search__input .form__addon__item {
    border: none;
  }

  .search-filter-wrapper .form__group.search__input .input__wrapper input {
    border: 1px none;
  }

  .rt-resizable-header {
    &:last-of-type {
      padding: 0;
    }
  }
}

.heart-icon {
  &:hover {
    color: @global-color-danger;
  }
}

.heart-filled-icon {
  color: @global-color-danger;
}

.listing-icon {
  margin: 2px;
}
