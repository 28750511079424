.box {
  .trimChildren();

  background: @global-color-white;
  padding: @global-whitespace-large;
  box-shadow: @app-shadow-heavy;

  // Add space if adjacent element
  & + & {
    margin-top: @global-whitespace-large;
  }

  &--preference {
    .box__subtitle {
      @media (min-width: @global-screen-small-min) {
        max-width: 90%;
      }
    }
  }
}

.box__title-wrapper {
  margin-bottom: @global-whitespace-large;
  text-align: center;

  &:not(:first-of-type) {
    margin-top: @global-whitespace-large;
  }
}

// For use with more complex headers
.box__title-wrapper--flex {
  display: flex;
  align-items: center;
  margin-bottom: @global-whitespace-large;

  // Override for Tags page; where we're re-using the same DOM, but
  // need to make sure header is always centered
  > * {
    &:only-child {
      flex-basis: 100% !important;
      padding: 0 !important;
    }
  }

  @media (min-width: @global-screen-small-min) {
    > * {
      &:not(.box__title__content-flex) {
        flex-basis: 20%;
      }

      &:last-child {
        text-align: right;
      }
    }

    .box__title__content-flex {
      padding: 0 @global-whitespace-xsmall;
      flex-basis: 60%;
    }
  }

  @media (max-width: @global-screen-small-max) {
    flex-direction: column;

    .box__title__flex__trigger {
      order: 2;
      margin-top: @global-whitespace-xsmall;
    }

    .box__title__flex__status {
      order: 1;
      margin-top: @global-whitespace-xsmall;
    }

    &:not(:first-of-type) {
      margin-top: @global-whitespace-large;
    }
  }
}

.box__title__content-flex {
  text-align: center !important;
}

.box__title {
  font-size: @global-font-size-medium;
  font-weight: @global-font-weight-normal;
  text-transform: uppercase;
}

.box__subtitle {
  color: @global-font-color-muted;
  font-size: @global-font-size-small;
  margin-top: @global-whitespace-xsmall;

  @media (min-width: @global-screen-small-min) {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

.box__subtitle-warning {
  color: @global-color-accent;
  font-size: 1.23rem;
}

.box__touchid-icon {
  width: 2em;
  height: 2em;
  display: block;
  margin: 0 auto @global-whitespace-small auto !important;

  .icon-stroke {
    stroke: #fc476b;
  }
}

.form__group__language-translation-switch {
  & + .rhinoswitcher__text {
    @media (max-width: @global-screen-nano-min + 40) {
      display: block;
      width: 68%;
    }
  }
}


// For use with communication consent
.box__consent {
  .box__consent__title-wrapper {
    text-align: center;
  }

  .box__consent__subtitle-wrapper {
    display: flex;
    justify-content: center;
    margin: @global-whitespace-xsmall 0 @global-whitespace-large 0;

    .button__consent {
      .box__consent__subtitle {
        font-size: @global-font-size-base;
        color: @global-color-gray-darker;
        margin-right: @global-whitespace-xsmall;

        @media (max-width: 375px) {
          font-size: @global-font-size-small;
        }
      }

      &.is-active {
        border-bottom: @global-color-primary solid 2px;

        .box__consent__subtitle {
          color: @global-color-primary;
          font-weight: @global-font-weight-bold;
        }
      }
    }

    @media (max-width: @global-screen-nano-min) {
      flex-wrap: wrap;
    }
  }
}

.box__collapse {
  position: absolute;
  color: @global-color-primary;
  top: 0;

  &&--right {
    right: 0;
  }

  &&--left {
    left: 0;
  }
}

