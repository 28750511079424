//
// Component
//


.avatar-editor {
  text-align: center;
}

.avatar-editor__container {
  canvas {
    background: @global-color-panel;
    border: 1px solid @global-border-color;
    border-radius: 50%;
    width: 100%;
    margin-bottom: @global-whitespace-base;

    &:hover,
    &:focus {
      cursor: grab;
    }
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.avatar-editor__controls {
  text-align: center;
  margin-top: @global-whitespace-small;
}

.avatar-editor__rotate {
  margin-bottom: @global-whitespace-large;
  font-size: @base-h2-font-size;
  transform-origin: center center;
  transition: 0.25s transform @global-trans-timing-cubic;

  &:hover,
  &:focus {
    transform: scale(1.1);
  }
}

.avatar-editor__container__upload {
  color: @global-color-primary;
  cursor: pointer;
  display: block;
  font-weight: @global-font-weight-normal;
}

.avatar-editor__input {
  .mixin-sr-only();
}
