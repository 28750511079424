//
// Component
//


.app-system-message {
  align-items: center;
  background: @global-color-primary;
  display: flex;
  justify-content: center;
  padding: @global-whitespace-base;
  position: relative;
  width: 100%;
}

.app-system-message__container {
  max-width: 600px;
  position: relative;
  text-align: center;
  width: 100%;
}

.app-system-message__copy {
  color: @global-color-white;
  font-size: @global-font-size-large;
  margin-bottom: @global-whitespace-large;
}

.app-system-message__error-code {
  border: 1px solid @global-color-white;
  color: @global-color-white;
  display: inline-block;
  font-size: @global-font-size-small;
  font-weight: @global-font-weight-bold;
  padding: @global-whitespace-small @global-whitespace-base;
}

.app-system-message__headline {
  color: @global-color-white;
  font-size: @base-h1-font-size;
  font-weight: @global-font-weight-bold;

  @media (min-width: @global-screen-xsmall-min) {
    font-size: (@base-h1-font-size * 2);
  }
}

.app-system-message__image {
  margin: 0 auto @global-whitespace-base;
  max-width: 320px;
}


//
// App Not Found Wrapper
//


.app-system-message__wrapper {
  display: flex;
}
