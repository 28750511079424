// 1. Override `data-vh-calculated` inline style
.rhinopay {
  background: @global-color-gray-lightest;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 100vh;
  height: auto !important;
}

.rhinopay__content {
  flex: 1 0 auto;
}

.rhinopay__content__container {
  &:extend(.app-page__container__inner);

  margin: 0 auto;
  padding: @global-whitespace-base;

  @media (min-width: @global-screen-small-min) {
    padding: (@global-whitespace-large * 2) @global-whitespace-large;
  }

  .box__title-wrapper {
    img {
      height: 60px;
    }
  }
}

.rhinopay__payment-form__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media (max-width: @global-screen-small-max) {
    margin-bottom: @global-whitespace-large;
  }

  .box__title {
    margin-bottom: @global-whitespace-small;

    @media (min-width: @global-screen-small-min) {
      margin-bottom: @global-whitespace-large;
    }
  }
}

.rhinopay__footer {
  background: lighten(@global-color-gray-darker, 4%);
  flex: none;
  text-align: center;
}

.rhinopay__footer__branding {
  background: @global-color-gray-darker;
  color: @global-color-gray;
  font-size: @global-font-size-small;
  padding: @global-whitespace-base;

  a {
    color: @global-color-gray;
  }
}

.rhinopay__modal__header,
.rhinopay__patient__payment-header {
  text-align: center;
  border-top-right-radius: @modal-border-radius;
  border-top-left-radius: @modal-border-radius;

  img {
    height: 45px;
    margin-top: @global-whitespace-large;
    margin-bottom: @global-whitespace-xsmall;
  }
}

.rhinopay-wrapper {
  background: @global-color-white;
  padding: @global-whitespace-large;
  box-shadow: @app-shadow-heavy;
  width: 97%;
  margin: @global-whitespace-medium - 0.2 auto;
  min-height: 500px;
  position: relative;
}

.rhinopay-wrapper__filter-label {
  font-size: @global-font-size-small;
  color: @global-color-gray;
  text-align: left;
  padding: 0 0 10px;
  line-height: @global-whitespace-xlarge;
  height: @global-whitespace-xlarge;
}

.payment-request {
  &__circle {
    width: @global-font-size-large - 1;
    height: @global-font-size-large - 1;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;

    &--paid {
      background-color: @global-color-success;
    }

    &--sent,
    &--cancelled {
      background-color: transparent;
      border: 1px solid @global-color-gray-dark;
    }
  }

  &__status-label {
    &--paid {
      padding-left: 7px;
      color: @global-color-success;
    }

    &--sent,
    &--cancelled {
      padding-left: 7px;
    }
  }
}

.payment-request__button-link {
  color: @global-color-gray-darker !important;
  text-decoration: underline;
}

.rhinopay-wrapper__filter {
  .daterange__dropdown {
    text-align: center;
    margin-right: @global-whitespace-small;

    @media (max-width: @global-screen-xsmall-max) {
      max-width: 100%;
    }

    .button {
      @media (max-width: @global-screen-xsmall-max) {
        font-size: @global-font-size-small;
      }
    }
  }

  .dropdown {
    .button {
      @media (max-width: @global-screen-small-max) {
        height: @global-whitespace-large;
      }
    }
  }

  .dropdown__toggle__text {
    @media (max-width: @global-screen-small-max) {
      font-size: @global-font-size-small;
    }
  }

  .button {
    @media (max-width: @global-screen-small-max) {
      height: @global-whitespace-large;
    }
  }
}

