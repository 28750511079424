
.hipaa-campaign-dropdown {
  width: 30rem;

  .button__text-wrapper {
    justify-content: flex-start;
  }
}

.hipaa-campaign-type {
  label {
    display: flex;
    flex-wrap: wrap;
    margin-left: @global-whitespace-large;
  }

  &__name {
    margin-left: @global-whitespace-large*4;

    @media (max-width: @global-screen-small-max) {
      margin-left: @global-whitespace-large*2;
    }

    @media (max-width: @global-screen-xsmall-max) {
      margin-left: @global-whitespace-small;
    }
  }

  .rhinodio__label::before {
    right: auto;
    left: 1.6rem;
  }

  .rhinodio__label::after {
    right: auto;
    left: 1.9rem;
  }
}
