.contact-list__nocontacts_wrapper {
  background-color: @global-background;
  border-top: none;
}

.app-page--contact {
  .tabs-content {
    display: flex;
    flex: 1 1;
    flex-direction: column;
  }

  .tabs-content__pane {
    flex: 1 1;
    flex-direction: column;

    &.is-active {
      display: flex;

      .resource__intro__title__content {
        font-weight: @global-font-weight-normal;
      }
    }
  }

  .nav-tabs__item__link {
    padding: 5px 10px 3px;
    font-size: @global-whitespace-base;
    color: @global-color-gray-darker;
  }

  .contact-section {
    padding: 15px 0 0;
  }
}

.summary-panel__wrapper {
  &--contact-list {
    .summary-panel__section {
      border: none;
    }
  }
}

.list-panel__contact-list {
  .resource__intro__media {
    width: 45px;
    height: 45px;
    background: @global-color-gray-light;
    border-radius: 50%;
    color: @global-color-white;

    .u-flex {
      justify-content: center;
      margin: 7px 0 0;
    }

    svg {
      width: 28px !important;
      height: 28px !important;
    }
  }
}

.dropdown-search-select {
  padding-left: @global-whitespace-base;
  padding-top: @global-whitespace-small;
  padding-bottom: @global-whitespace-small;

  .dropdown {
    width: 100%;
  }

  .button--input {
    width: 100%;
  }

  .button--primary {
    width: 100%;
  }

  .button__text-wrapper {
    justify-content: space-between;
  }

  .dropdown__menu {
    width: 100%;
  }

  .search__input {
    margin-bottom: 0;
  }
}

.multi-select__dropdown--fixed-height > div:nth-child(1) {
  max-height: 300px !important;
}

.button__account-setup {
  border-radius: 50%;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}

.dropdown-menu li {
  position: relative;
}

.dropdown-menu .dropdown-submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: -7px;
}

.dropdown-menu .dropdown-submenu-left {
  right: 100%;
  left: auto;
}

.dropdown-menu > li:hover > .dropdown-submenu {
  display: block;
}
