.feature-tour {
  color: @global-color-white;
  position: absolute;
  z-index: @modal-zindex;
  width: 350px;
  padding: 0 15px 15px 15px;
  background: @global-color-education;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.23);
  border-radius: 4px;

  @media (max-width: @global-screen-small-max) {
    width: 250px;
  }

  p {
    margin-top: 0;
    margin-bottom: @global-whitespace-base;
  }
}

.feature-tour--fadeOut {
  visibility: hidden !important;
  opacity: 0;
  transition: visibility 0s linear 0.5s, opacity 0.5s;
}

.feature-tour--fadeIn {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 0.5s;
}

.feature-tour__button {
  &--action {
    margin-right: 5px;
    color: @global-color-education;
    background: @global-color-white;

    &:hover {
      color: @global-color-education;
      background: @global-color-white;
    }
  }
}

.feature-tour__header {
  display: flex;
  justify-content: space-between;
}

.feature-tour__header--title {
  padding-top: 12px;
}

.feature-tour__icon {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-bottom: 8px solid @global-color-education;
  position: absolute;
  right: 50%;
  top: -15px;

  &--left {
    border-top: 8px solid transparent;
    border-right: 8px solid @global-color-education;
    border-left: 8px solid transparent;
    border-bottom: 8px solid transparent;
    position: absolute;
    top: 40%;
    left: -15px;
  }

  &--left-bottom {
    border-top: 8px solid transparent;
    border-right: 8px solid @global-color-education;
    border-left: 8px solid transparent;
    border-bottom: 8px solid transparent;
    position: absolute;
    bottom: 20%;
    left: -15px;
  }

  &--right {
    border-top: 8px solid transparent;
    border-left: 8px solid @global-color-education;
    border-right: 8px solid transparent;
    border-bottom: 8px solid transparent;
    position: absolute;
    top: 40%;
    right: -15px;
  }

  &--top {
    border-top: 8px solid transparent;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: 8px solid @global-color-education;
    position: absolute;
    right: 50%;
    top: -15px;
  }

  &--bottom {
    border-top: 8px solid @global-color-education;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: 8px solid transparent;
    position: absolute;
    right: 50%;
    bottom: -15px;
  }

  &--top-right {
    border-top: 8px solid transparent;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: 8px solid @global-color-education;
    position: absolute;
    right: 20%;
    top: -15px;
  }

  &--top-left {
    border-top: 8px solid transparent;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: 8px solid @global-color-education;
    position: absolute;
    left: 20%;
    top: -15px;
  }

  &--bottom-right {
    border-top: 8px solid @global-color-education;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: 8px solid transparent;
    position: absolute;
    right: 20%;
    bottom: -15px;
  }

  &--bottom-left {
    border-top: 8px solid @global-color-education;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: 8px solid transparent;
    position: absolute;
    left: 20%;
    bottom: -15px;
  }
}

.feature-tour-callout {
  color: @global-color-white;
  position: absolute;
  z-index: @modal-zindex;
  padding: @global-whitespace-xsmall @global-whitespace-small;
  background: @global-color-education;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  display: flex;

  p {
    font-size: @global-font-size-small;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: @global-whitespace-small;
  }

  button {
    font-size: @global-font-size-xsmall !important;
  }
}

.feature-tour-callout__icon {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-bottom: 8px solid @global-color-education;
  position: absolute;
  right: 50%;
  top: -15px;

  &--left {
    border-top: 8px solid transparent;
    border-right: 8px solid @global-color-education;
    border-left: 8px solid transparent;
    border-bottom: 8px solid transparent;
    position: absolute;
    top: 23%;
    left: -15px;
  }

  &--right {
    border-top: 8px solid transparent;
    border-left: 8px solid @global-color-education;
    border-right: 8px solid transparent;
    border-bottom: 8px solid transparent;
    position: absolute;
    top: 23%;
    right: -15px;
  }
}
