.connected-party__bottom-note {
  .u-text-small;

  background: @global-color-panel;
  border-top: 1px solid @global-border-color;
  margin: @global-whitespace-base -@global-whitespace-base -@global-whitespace-base -@global-whitespace-base;
  padding: @global-whitespace-base;
}

.connected-party__bottom-note--important {
  background: @global-color-danger-light;
  border-top-color: @global-color-danger;
}

#connected-parties {
  .resource__body {
    overflow: inherit;
  }

  .resource__wrapper {
    transform: none;
    -webkit-transform: none;
  }
}

.connected-party__bottom-note__content {
  display: flex;
  width: 100%;

  > * + * {
    margin-left: @global-whitespace-small;
  }
}
