.checkbox-columns {
  max-width: 50rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;

  .checkbox-item {
    min-height: 5rem;

    &__route {
      white-space: normal;
      word-wrap: break-word;
    }
  }
}

.routing__manager__route {
  .resource__intro {
    width: 40%;
    min-width: 40rem;
  }
}

.resource-group\@small & {
  .routing__manager__route {
    .resource__intro {
      min-width: inherit;
    }
  }
}
