.permissions__settings-table-container {
  margin-top: @global-whitespace-small;

  @media (max-width: 350px) {
    overflow: scroll;
  }
}


.permissions__setting-headers {
  font-size: @global-font-size-small;
  color: @global-font-color-muted;
  margin-bottom: @global-whitespace-small;
}

// Features a number of overrides to our standard table
.permissions__settings-table {
  border-bottom: 1px solid @global-border-color;

  @media (max-width: 375px) {
    table-layout: fixed;
  }

  th {
    border-top: 0;
    vertical-align: bottom;
    padding-bottom: @global-whitespace-small;

    &:first-child {
      padding-left: 0;
      width: 100%;

      @media (max-width: 375px) {
        width: 114px;
      }
    }

    &:not(:first-child) {
      text-align: center;
      text-transform: none;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  tbody {
    tr {
      border-left: 1px solid @global-border-color;
      border-right: 1px solid @global-border-color;
    }
  }

  td {
    &:first-child {
      width: 100%;
    }

    &:not(:first-child) {
      text-align: center;
    }

    &:last-child {
      padding-right: 0;
    }

    &.rhinoform-view {
      text-align: right;

      .rhinobox {
        margin-right: 2px;
        display: inline-flex;
      }
    }

    &.integrated-contact-view {
      text-align: right;

      .rhinobox {
        margin-right: 54px;
        display: inline-flex;
      }
    }
  }

  // Override checkboxes to horizontally center them
  // 1. Checkboxes have a small offset for labels - since we don't have them here, we can kill it
  .form__group {
    display: inline-flex;
    vertical-align: top;
    margin-left: @global-whitespace-xsmall;
  }

  & + & {
    margin-top: @global-whitespace-base;
  }

  .rhinoswitcher__label {
    width: 32px;
    height: 20px;

    &::after {
      width: 15px;
      height: 16px;
    }
  }

  .rhinoswitcher {
    input:checked {
      & + label::after {
        right: 3px;
        left: auto;
      }
    }
  }
}

.resource__wrapper.is-checkbox.is-selected .resource::after {
  border-radius: @global-border-radius !important;
}

.mobile-toggle {
  background: @global-color-gray-lightest;

  td {
    vertical-align: middle;

    &:last-child {
      text-align: right;
    }

    &:first-child {
      padding-left: @global-whitespace-large;
    }
  }
}
