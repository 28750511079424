@iconSize: 1.5rem;
@appointmentRowHeight: 2.3rem;

.appointments {
  &__circle {
    width: @iconSize;
    height: @iconSize;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;

    &--cancelled {
      background-color: @global-color-danger;
    }

    &--confirmed {
      background-color: @global-color-success;
    }

    &--unconfirmed {
      background-color: transparent;
      border: 1px solid @global-color-gray-dark;
    }

    &--unread {
      &::before {
        content: '';
        display: inline-block;
        margin-right: @global-whitespace-xsmall;
        width: @resource-unread-size;
        height: @resource-unread-size;
        background: fadeout(@global-color-primary, 75%);
        border: 1px solid @global-color-primary;
        border-radius: 50%;
      }
    }
  }

  &__icon {
    border-radius: 50%;
    padding: 0.2rem;
    height: @iconSize;
    width: @iconSize;
    color: @global-color-white;
    background-color: @global-color-success;

    &--expired {
      background-color: @global-color-danger;
    }

    &--pending {
      background-color: @global-color-accent;
    }
  }

  .nav-tabs__item {
    margin-left: @global-whitespace-small;
    margin-right: @global-whitespace-small;
  }

  &__status-label {
    padding-left: 7px;
  }

  &__message-contact-button {
    float: right;
  }

  &--non-bulk {
    .rt-td,
    .rt-th {
      &:first-child {
        left: 0 !important;
      }
    }
  }

  &__table-header {
    min-height: 120px;
  }
}

.appointments-header {
  &__notification-panel {
    width: 60%;
    display: inline-block;
    position: relative;
  }
}

.appointments__icon-bold {
  .icon-stroke {
    stroke-width: 2px;
  }
}

.appointments-checkbox {
  .rhinobox__label {
    font-size: 0;
    margin-left: 0.6rem;
  }
}

.appointments-count {
  display: inline-flex;
  font-size: @global-font-size-medium;
  font-weight: @global-font-weight-bold;
  line-height: @global-line-height-small;

  @media (max-width: 1350px) and (min-width: @global-screen-medium-min) {
    font-size: @global-font-size-small;
  }
}

@media (max-width: @global-screen-small-max) {
  .appointments-wrapper {
    .upcoming-grid {
      padding: 0;
    }
  }

  .appointments-header {
    &__notification-panel {
      width: 90%;
    }
  }
}

.dropdown-chekbox {
  padding-left: @global-whitespace-small;

  .dropdown__toggle__text {
    font-size: @global-font-size-small;
    position: relative;
    top: -4px;
  }
}

.appointment-tab__navTabsItem {
  padding: 0 0.6rem 0.2rem 0.6rem;
  margin: 0 1.5rem;
  font-weight: bold;
}

.appointment-status__row {
  min-height: @appointmentRowHeight;
}

.appointment-forms {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-height: @appointmentRowHeight;

    .form__status {
      margin-right: @global-whitespace-medium;
      flex-direction: row;
      display: flex;
      align-items: center;

      &__completed {
        width: 2.1rem;
        height: 2.1rem;
        color: @global-color-primary;
        margin-left: @global-whitespace-xsmall;
      }
    }
  }
}

.appointment_campaign_rules {
  box-shadow: unset !important;
  padding: @global-whitespace-base !important;
  border: 1px solid #e5e5e5 !important;
  margin-top: @global-whitespace-large;

  option {
    white-space: normal;
  }
}

.appointment-campaign-rule__input {
  width: 7.5rem;
}

