//
// Component
//


//
// Responsive SVG
//
// Use with inline SVGs for proper cross browser scaling
// See https://css-tricks.com/scale-svg/


.responsive-svg {
  height: 0;
  padding: 0;
  padding-bottom: 100%; // override this inline for aspect ratio other than square 100% * viewbox height / viewbox width
  position: relative;
  width: 100%;

  svg {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
