// Additional overrides on top of our `naked` input to appear as normal text
// 1. Fudging size since you can't say "make input width the same as the value" without messy JS -- we control the length of the password, so this should be fine.
.app-temp-password {
  input {
    height: auto !important;
    width: 95px;
    line-height: 1;
    font-weight: @global-font-weight-bold;
  }
}
