//
// Component
//


// 1. Set page content to lower z-index than header for `box-shadow`
.app-page {
  display: flex;
  flex: 1 1 0%;
  overflow: hidden;
  position: relative;
  z-index: 1; // 1
  padding-top: @global-whitespace-base;
}


//
// App Page Container
//


.app-page__container {
  flex: 1;
  overflow-y: auto;
  position: relative;
  -webkit-overflow-scrolling: touch;
}


//
// App Page Container Inner
//


.app-page__container__inner {
  margin: 0 auto;
  padding: @global-whitespace-large @global-whitespace-base;
  max-width: @global-screen-small-min;
}


.app-page__container__inner--form {
  margin: 0 auto;
  padding: @global-whitespace-large @global-whitespace-base;
  max-width: @global-screen-small-min;
  height: 850px;
}

.app-page__container__inner--large {
  max-width: @global-screen-large-min;
}

.app-page__standalone {
  overflow-y: auto;
  min-height: 100vh;
  background: @app-background-color;
}

.app-logo__standalone {
  width: 240px;
}


//
// Page header
//

.app-page__header {
  display: flex;
  flex: none;
  text-align: center;
  margin-bottom: @global-whitespace-base;
  position: relative;
  min-height: @global-height;
  z-index: 2;
}

.app-page__header__title {
  flex: 1;
  font-size: @global-font-size-large;
  font-weight: @global-font-weight-bold;
  padding: 0;
  position: absolute;
  left: 0;
  right: 0;
  align-self: center;
}

.app-page__header__titlesub {
  line-height: @global-font-size-small;
  font-size: @global-font-size-small;
  color: @global-color-gray;
  font-weight: normal;
}

.app-page__header__title__action {
  .u-text-small;
  .u-text-primary;
  .u-font-weight-normal;
}

.app-page__header__filter,
.app-page__header__action {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.app-page__header__filter {
  z-index: @zindex-app-navigation;
  left: 0;
}

// Increase size of filter icon
.app-page__header__filter__button {
  height: @global-height;

  svg {
    .icon--large;
  }

  .mixin-filter-notify();
}

.app-page__header__action {
  right: 0;

  .button {
    border-radius: 50%;
  }
}

.app-page__header__action__close {
  vertical-align: middle;
  height: @global-height;
}
