.rhinoform__cover {
  position: relative;

  .cover__header {
    background: @global-color-primary;
    color: @global-color-white;
  }

  .cover__header__container {
    padding: @global-font-size-medium @global-font-size-base;
  }

  .cover__body {
    height: calc(100vh - 130px);
    overflow: auto;
  }

  .form__explanation-message {
    font-style: normal;
    font-size: @global-font-size-xsmall;
  }

  .app-page-loader-outer {
    position: absolute;
    min-height: calc(100vh - 130px);
    width: 100%;
  }

  .close {
    position: absolute;
    left: 15px;
    opacity: 0;
  }

  .hide-form {
    display: none;

    & + .rhinodio {
      border: none;
    }
  }

  .button--outline {
    background-color: transparent !important;
    border-color: @global-color-gray-darker !important;
  }
}

.rhinoform__tabs {
  position: absolute;
  width: 90%;
  top: 0;
  z-index: 1;
  left: 0;
  right: 0;
  margin: 0 auto;

  @media (max-width: @global-screen-small-max) {
    left: 22%;
  }

  .nav-tabs {
    justify-content: center;
  }

  .nav-tabs__item {
    cursor: default;

    &::after,
    &::before {
      display: none;
    }

    &.is-active {
      .nav-tabs__item__link {
        color: @global-color-white;
        font-weight: 600;
        opacity: 1;
        padding: @global-font-size-base;
      }

      &::after {
        content: "";
        height: 2px;
        background: @global-color-white;
        display: block;
        position: absolute;
        bottom: 10px;
        top: auto;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 90%;
      }
    }
  }

  .nav-tabs--justified-none {
    .nav-tabs__item {
      &:last-child .nav-tabs__item__link::after {
        display: none;
      }
    }
  }

  .nav-tabs__item__link {
    color: @global-color-white;
    opacity: 0.7;
  }

  &.enable-link {
    .nav-tabs__item {
      cursor: pointer;
    }
  }
}

.rhinoform {
  &__button {
    .form__explanation-message {
      color: @global-color-gray-darker;
      line-height: 1.5;
    }
  }

  &__iframe-cover {
    iframe {
      width: 100%;
      min-height: calc(100vh - 200px);
    }
  }

  .app-page-loader-outer {
    background-color: rgba(255, 255, 255, 0.7);
  }

  .cover__footer {
    @media (max-width: @global-screen-small-max) {
      position: absolute;
      width: 100%;
      top: 92%;
    }
  }
}

.rhinoform__create-button {
  position: relative;

  input {
    max-width: 154px;
    position: absolute;
    top: 0;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    padding: @global-whitespace-small 0;
  }

  .button[disabled] {
    & + input {
      pointer-events: none;
    }
  }
}

.uploaded-pdf {
  color: @global-color-primary;
  padding: @global-font-size-medium 0 0;

  span {
    padding-left: @global-font-size-xsmall;
  }
}

.rhinoform__pdf {
  .rhinodio__label {
    padding: @global-whitespace-xsmall;
  }

  .rhinoform__pdf__container {
    display: flex;
    flex-direction: row;

    .rhinoform__pdf__image {
      width: 14rem;
      margin: 0;

      img {
        margin: auto;
        display: block;
      }

      @media (max-width: @global-screen-xsmall-max) {
        width: 10rem;
      }
    }

    .rhinoform__pdf__label {
      flex-direction: column;
      margin-left: @global-whitespace-small;
      margin-top: @global-whitespace-small;
    }
  }
}

.rhinoform__action-button {
  text-align: center;
  color: @global-color-primary;

  button {
    height: 3rem;
  }

  &:hover,
  &:hover > * {
    color: darken(@global-color-primary, 5%);
  }

  &--delete,
  &--delete > * {
    color: @global-color-danger;

    &:hover,
    &:hover > *,
    &:active,
    &:active > *,
    &:focus,
    &:focus > * {
      color: darken(@global-color-danger, 5%);
    }
  }

  &__icon {
    width: 2.5rem;
    height: 2.5rem;

    &--bold {
      .icon-stroke {
        stroke-width: 0.15rem;
      }
    }
  }

  &__label {
    font-size: @global-font-size-xsmall;
    line-height: @global-font-size-xsmall;
  }
}


.form__block-group__warning_desc {
  font-size: @global-font-size-small;
  color: @global-color-danger;
}
