.member-profile__login-box {
  .rhinobox__label {
    cursor: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.6rem 1.6rem 1.6rem 1.6rem;

    &.member-profile__login-box--mfa {
      @media (max-width: @global-screen-xsmall-min) {
        display: block;
      }
    }
  }

  .rhinobox__label--password-active {
    padding: 1.6rem 1.6rem 1.6rem 1.6rem;
  }

  &--mfa {
    border-top: 1px solid @global-color-gray-lighter;

    button {
      font-size: 1.4rem;
    }

    & > div {
      padding: @global-whitespace-small 0;
    }
  }

  &--password {
    border-top: 1px solid @global-color-gray-lighter;
  }
}

