.prescription-filters__dropdown {
  .dropdown__menu--wide {
    min-width: 35rem;

    @media (max-width: @global-screen-xsmall-max) {
      min-width: 28rem;
    }
  }

  .dropdown__menu__scroll {
    overflow-y: hidden;
  }

  .form__block-group--checkbox {
    border-top: none;
  }

  .rhinobox__label::before {
    top: 50%;
    transform: translateY(-50%);
  }
}

.form-row--prescriptions {
  .rt-td {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: normal;
    word-wrap: break-word;
  }
}

.prescription-campaigns__template {
  white-space: pre-line;
}

.prescription-reports__table__rows {
  flex: 1;
  font-size: @global-font-size-small;
  font-weight: @global-font-weight-bold;
  padding-bottom: @global-whitespace-small;
  padding-top: @global-whitespace-medium;
}
