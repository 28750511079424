.tabs__header {
  margin-bottom: @global-whitespace-base;
}

.sub-nav__tabs__header {
  margin-bottom: @global-whitespace-base;
  margin-top: -@global-whitespace-base;

  button {
    padding: @global-whitespace-xsmall;
  }

  .is-active {
    padding: @global-border-radius;
    border-bottom: 2px solid @base-link-color;
  }
}

.tabs__trigger {
  &.is-active {
    font-weight: @global-font-weight-bold;
    pointer-events: none;
    cursor: default;
    color: @base-link-color;
  }

  &:not(.is-active) {
    color: @global-font-color;

    &:hover,
    &:focus {
      color: @base-link-color;
    }
  }
}
