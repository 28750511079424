
.mention-message__compose {
  border: none !important;
  background-color: white !important;
  word-break: break-word;
  color: @variable-message-compose-color;
  max-height: 12rem;
  overflow: auto;

  &:focus {
    outline: 0;
  }
}

.mention__variable {
  &:extend(.pill--primary);

  display: inline;
  pointer-events: none;
  color: @global-color-primary;
  background-color: @global-color-primary-light;
  border-radius: @global-border-radius;
  margin-bottom: 0.1rem; // 2
  cursor: text;
  vertical-align: baseline;

  // Hide squigglies
  i {
    display: none;
  }

  // Do not show underscore
  b {
    opacity: 0;
    width: 0.3rem;
    font-size: 1rem;
  }

  div {
    cursor: text;
    display: inline-block;
  }
}

.mention__variable__container {
  display: inline;
}

.mention__variable-inline {
  &:extend(.pill--primary);

  line-height: @global-line-height-small;
  color: white;
  background-color: #66acde;
  border-radius: @global-whitespace-xsmall;
  padding: 0 4px 2px 4px;
  margin-bottom: 0.1rem; // 2
  cursor: default;
  user-select: none;
  vertical-align: baseline;

  // Hide squigglies
  i {
    display: none;
  }

  // Do not show underscore
  b {
    opacity: 0;
    width: 0.3rem;
    font-size: 1rem;
  }

  div {
    display: inline-block;
  }
}

.mention-panel {
  position: absolute !important; // override inline styles in library
  top: 0;
  transform: translateY(-100%);
  width: 100%;
  z-index: @modal-zindex;
}

.mention-group__item {
  .avatar--default {
    background-color: white;
    border-radius: 0;
  }
}
