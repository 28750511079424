.appointment-data-setup {
  .app-panels > * {
    overflow: unset;
  }
}

.appointment-data-setup__footer {
  margin-bottom: @global-whitespace-medium;
}

.appointment-data-setup__wrapper {
  min-height: 55rem;
  display: flex;
  flex-direction: column;
  flex: 1;

  &.box {
    .empty-message__wrapper {
      margin-bottom: auto;
    }
  }

  .list-panel__body__scroll {
    border-top: none;
  }

  @media (max-width: @global-screen-xsmall-max) {
    min-height: 45rem;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}


.appointment-data-setup__types__title {
  text-align: center;
  text-transform: uppercase;
  padding-top: @global-whitespace-medium / 2;
  padding-bottom: @global-whitespace-small;
}

.appointment-data-setup__title {
  font-size: @global-font-size-large;
  font-weight: @global-font-weight-bold;
  display: flex;
  text-align: center;
  text-transform: capitalize;
  padding-top: @global-whitespace-medium / 2;
  padding-bottom: @global-whitespace-small;
  align-items: center;

  @media (max-width: @global-screen-small-max) {
    display: inline-block;
    width: 100%;
  }

  .nav-tabs__item {
    width: auto;
    line-height: 30px;
    margin: 0 10px;
    padding: 0 5px;
    margin-bottom: 0;
  }

  ul {
    margin-bottom: 0;
  }
}

.appointment-data-setup__header--ignored {
  display: flex;
  justify-content: center;
}

.appointment-data-setup__input-wrapper {
  input {
    @media (max-width: @global-screen-small-max) {
      font-size: @global-font-size-small;
      height: @global-height-small;
    }
  }
}
