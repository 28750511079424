.search-filter-wrapper {
  height: 3em;
  border: 1px solid fade(@global-color-primary, 50%);
  border-radius: @global-border-radius;
  margin-bottom: 1em;
  margin-left: 1rem;

  .dropdown {
    width: 155px;
    padding: 0 0.5em;
    background-color: fade(@global-color-primary, 5%);
    border-right: 1px solid fade(@global-color-primary, 50%);
    padding-top: 0.2em;

    .app-page__header__filter__button {
      width: 100%;

      span.button__text-wrapper {
        justify-content: left !important;
      }
    }
  }

  .form__group.search__input {
    width: 100%;
    margin: 0;

    .input__wrapper {
      input {
        border: 1px solid none;
      }
    }
  }
}
